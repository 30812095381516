import React from "react"

const ChartFillIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="19"
    viewBox="0 0 26 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="25.9905" height="19" rx="3" fill="#C5D1DA" fillOpacity="0.8" />
  </svg>
)

export default ChartFillIcon
