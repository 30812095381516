import React from "react"
import styled from "@emotion/styled"
import { theme } from "kui-basic"
import { StatusButtonProps, StatusButtonStylesProps } from "./types"
import { DynamicRoutes } from "../../../../../types/route"
import LinkButton from "../../../../../components/common/LinkButton"
import { statusColors } from "./content"

const StatusButton = (props: StatusButtonProps) => {
  const { inspection, variant, label, className } = props
  const link = DynamicRoutes.inspection(inspection.apartment.id, inspection.id)

  return (
    <StyledButton
      href={link}
      labelColor={null}
      color={variant}
      variant="transparent"
      className={className}
    >
      {label}
    </StyledButton>
  )
}

export default StatusButton

const StyledButton = styled(LinkButton)<StatusButtonStylesProps>`
  border: none;
  .KUI-Button_icon-container {
    justify-content: space-between;
  }
  background: ${({ color }) => statusColors[color].background};
  p {
    color: ${({ color }) => statusColors[color].color};
  }
  path {
    fill: ${({ color }) => statusColors[color].color};
  }
  &:hover {
    box-shadow: ${theme.shadows.shadow1};
  }
`
