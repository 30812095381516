import * as React from "react"
import { ButtonsPanel } from "kui-complex"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { css } from "@emotion/react"
import { Heading, Box } from "kui-basic"
import { ButtonsPanelStylesProps, MenuPanelProps } from "./types"

function MenuPanel({
  endComponent,
  label,
  handleChange,
  withContainer,
  tabs,
  tabPanels,
  ...other
}: MenuPanelProps) {
  return (
    <StyledButtonsPanel
      tabs={tabs || []}
      tabPanels={tabPanels || []}
      withContainer={!!withContainer}
      startComponent={
        <Box mr={3}>
          <StyledHeading size="h2" className="MenuLabel">
            {label}
          </StyledHeading>
        </Box>
      }
      endComponent={
        endComponent && <StyledEndComponent>{endComponent}</StyledEndComponent>
      }
      onChange={handleChange}
      {...other}
    />
  )
}

MenuPanel.defaultProps = {
  withContainer: true,
}

export default observer(MenuPanel)

const containerStyles = ({ withContainer }: ButtonsPanelStylesProps) =>
  !withContainer &&
  css`
    .KUI-ButtonTabs {
      max-width: unset;
      padding: 0;
    }
  `

const StyledButtonsPanel = styled(ButtonsPanel)<ButtonsPanelStylesProps>`
  padding: 0 0 12px 0;
  .KUI-ButtonTabs {
    > .KUI-Grid_Container {
      flex-grow: 1;
      width: auto;
    }
    @media (min-width: 1200px) {
      max-width: 1344px;
    }
  }
  .KUI-ButtonTab {
    margin-right: 0;
    border: none;
    height: 32px;
    &:not(.KUI-ButtonTab_active) {
      p {
        font-weight: 600;
        color: ${({ theme }) => theme.palette.grey.fiftyP};
      }
      &:hover:not(&:disabled) {
        background: transparent;
        box-shadow: none;
        p {
          color: ${({ theme }) => theme.palette.grey.seventy};
        }
      }
    }
  }
  ${containerStyles};
`

const StyledHeading = styled(Heading)`
  width: max-content;
`

const StyledEndComponent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
`
