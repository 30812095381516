import React from "react"
import { theme } from "kui-basic"
import ChartBarIcon from "../../../../../../../../../components/icons/ChartBarIcon"
import ChartFillIcon from "../../../../../../../../../components/icons/ChartFillIcon"

export const chartLegends = [
  {
    color: theme.palette.brand.main,
    label: "price in ML model",
    hint: "price calculation result based on historical data using ML model",
  },
  {
    color: "#2A4D69",
    label: "price in rental contract",
  },
  {
    color: "#FF6B8A",
    label: "price in advertising",
  },
  {
    color: "#5A82A8",
    label: "boundaries of the price deviation range",
  },
]

export const chartLegendsWithIcons = [
  { icon: <ChartFillIcon />, label: "allowable price deviation range" },
  { icon: <ChartBarIcon />, label: "volume of apartment ads" },
]
