import { UserPermissions } from "../../types/api/cabinet"
import { AccessPermissionActions } from "../../types/store/appStore"

export const matchesAPISubjectRoles = {
  maroom: "maroom",
  tenant: "renter",
  landlord: "owner",
}

export const matchesSubjectRoles = {
  maroom: "maroom",
  renter: "tenant",
  tenant: "tenant",
  owner: "landlord",
}

export const matchesApartmentStatuses = {
  "Rented out": "Rented",
  Closed: "Closed",
  Open: "Free",
  New: "New",
  "Free soon": "Free soon",
}

export const matchesAPICompanyTypes = {
  administrative_provider: "administrative",
  service_provider: "service",
  resource_provider: "resource",
}

export const matchesCompanyTypes = {
  administrative: "administrative_provider",
  service: "service_provider",
  resource: "resource_provider",
}

export const matchesFillingTypes = {
  finish: "finish",
  furniture: "furniture",
  electronics: "appliance",
  accessories: "accessorie",
}

export const matchesAPIFillingTypes = {
  finish: "finish",
  furniture: "furniture",
  appliance: "electronics",
  accessorie: "accessories",
}

export const matchesVisibilityVariants = {
  everybody: "everybody",
  renter: "tenant",
  owner: "landlord",
  nobody: "nobody",
}

export const matchesAPIVisibilityVariants = {
  everybody: "everybody",
  tenant: "renter",
  landlord: "owner",
  nobody: "nobody",
}

export const genderMatches = {
  M: "male",
  F: "female",
}

export const matchesServiceTypes = {
  open_ended: "openEnded",
  period: "period",
  once: "once",
  operating: "own",
  auto: "auto",
  pending: "pending",
}

export const matchesDocumentsStatuses = {
  active: "active",
  expires: "expire_soon",
  expired: "expired",
  archived: "archived",
}

export const matchesAPIDocumentsStatuses = {
  active: "active",
  expire_soon: "expires",
  expired: "expired",
  archived: "archived",
}

export const matchesAPIPeriodStatuses = {
  new: "Open",
  closed: "Closed",
  overdue: "Overdue",
}

export const userPermissions: Partial<
  Record<UserPermissions, AccessPermissionActions | AccessPermissionActions[]>
> = {
  housing_inspector_dashboard: "dashboardView",
  consultant_dashboard: "dashboardView",
  clients_list: "clientsView",
  clients_create: "clientCreate",
  apartments_list: "objectsView",
  apartments_create: "objectCreate",
  apartments_inspections: ["objectInspectionsTab", "objectInspectionsView"],
  apartments_descriptions: "objectDescriptionView",
  apartments_edit_descriptions: "objectDescriptionEdit",
  apartments_promotion: "objectPromotionView",
  apartments_ownership: "objectOwnershipView",
  apartments_overview: "objectOverviewView",
  apartments_documents: "objectDocumentsView",
  service_contracts_list: ["contractsView", "serviceContractsView"],
  rental_contracts_list: ["contractsView", "rentalContractsView"],
  apartments_edit_overview: "objectOverviewEdit",
  apartments_edit_ownership: "objectOwnershipEdit",
  apartments_appraisals: "objectAppraisalsTab",
  apartments_impairments: "objectImpairmentsTab",
  apartments_create_impairments: "objectImpairmentsCreate",
  apartments_create_appraisals: "objectAppraisalCreate",
  apartments_expenses_summary: ["objectExpensesView", "expensesSummaryView"],
}

export const serviceContractFieldsMatches = {
  rental_type: "type",
  notification_period: "notifyPeriodMonth",
  taxes_type: "agentType",
  allowed_children: "childrenAllowed",
  allowed_pets: "petsAllowed",
  long_term_type: "rentPeriodType",
  first_appraisal_interval_month: "firstAppraisalPeriod",
}

export const contractActivateConditionsMatches = {
  contract_and_period_months_does_match: "closedPeriods",
  has_no_permission: "permission",
}
