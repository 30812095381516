import React from "react"
import { Heading } from "kui-basic"
import { observer } from "mobx-react"
import { SectionHeaderProps } from "./types"

const icons = ["🎅", "🤶", "❄️", "☃️", "⛄", "🎄", "🎁", "🥂"]

const SectionHeader = (props: SectionHeaderProps) => {
  const { label, ...other } = props
  const randomIndex = Math.floor(Math.random() * icons.length)
  const randomEmoji = icons[randomIndex]

  return (
    <Heading size="h2" {...other}>
      {randomEmoji} {label}
    </Heading>
  )
}

export default observer(SectionHeader)
