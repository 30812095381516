import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import Loading from "../../../../../components/common/Loading"
import ApartmentOwnershipForm from "../../../forms/ownership/ApartmentOwnershipForm"
import Container from "../../../../../components/ui/Container"
import useApartmentStore from "../../../store"

function ApartmentOwnershipTab() {
  const { ownershipStore, overviewStore, setEditPermission } =
    useApartmentStore()

  useEffect(() => {
    setEditPermission("objectOwnershipEdit")
    if (overviewStore.id) {
      ownershipStore.getOwnershipInfo(overviewStore.id)
    }
  }, [overviewStore])

  if (ownershipStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Box pt={2} pb={3}>
      <Container>
        <ApartmentOwnershipForm />
      </Container>

      <LoaderState loader={ownershipStore.loader} onlyError />
      <LoaderState loader={ownershipStore.actionLoader} />
    </Box>
  )
}

export default observer(ApartmentOwnershipTab)
