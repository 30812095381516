import React from "react"
import { Box, Grid } from "kui-basic"
import { DashboardHeaderProps } from "./types"
import DashboardFilters from "../DashboardFilters"
import SectionHeader from "../../../../../components/common/SectionHeader"

const DashboardHeader = (props: DashboardHeaderProps) => (
  <Box pt={3} pb={4}>
    <Grid container justify="space-between" alignItems="center" wrap="nowrap">
      <SectionHeader label="Dashboard" />
      <DashboardFilters {...props} />
    </Grid>
  </Box>
)

export default DashboardHeader
