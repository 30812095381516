import React from "react"
import styled from "@emotion/styled"
import { theme } from "kui-basic"
import IconWithText from "../../../../../../../../../components/common/IconWithText"
import { PromotionFeatureParams, PromotionFeatureProps } from "./types"

const getFormattedLabel = (feature: PromotionFeatureParams) => {
  if (feature.hasPlural && Number(feature.value) > 1)
    return `${feature.value} ${feature.label}s`
  if (feature.oppositeLabel)
    return !feature.value ? feature.oppositeLabel : feature.label
  return `${feature.value} ${feature.label}`
}

const PromotionFeature = ({ feature }: PromotionFeatureProps) => {
  const icon =
    feature.oppositeIcon && !feature.value ? feature.oppositeIcon : feature.icon
  const label = getFormattedLabel(feature)

  return <StyledFeature icon={icon} text={label} />
}

export default PromotionFeature

const StyledFeature = styled(IconWithText)`
  margin-right: 16px;
  path {
    fill: ${theme.palette.grey.thirty};
  }
  &:last-child {
    margin-right: 0;
  }
`
