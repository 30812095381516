import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import { UserRole } from "../../../../types/api/cabinet"
import { callPromisesBySettings } from "../../../../utils/service/mapper"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import {
  ConsultantDashboardCounts,
  ConsultantDashboardSettings,
} from "../../types/store/consultantDashboard"
import { WorkIndicatorType } from "../../types/api/common"
import NewRentalContractsStore from "../entities/NewRentalContractsStore"
import ObjectsNeedTenantStore from "../entities/ObjectsNeedTenantStore"
import ObjectsNeedInitialStore from "../entities/ObjectsNeedInitialStore"
import ContractsStore from "../entities/ContractsStore"
import PendingContractsStore from "../entities/PendingContractsStore"
import { WorkIndicatorParams } from "../../types/store/common"

const countTypes = {
  newContractsCount: "new_rental_contracts",
  newContractsRent: "new_rental_contracts_month_payment",
  newContractsCommission: "new_rental_contracts_commission_fees",
}

class ConsultantDashboardStore {
  newContractsCount: WorkIndicatorParams | null

  newContractsRent: WorkIndicatorParams | null

  newContractsCommission: WorkIndicatorParams | null

  newContractsList: NewRentalContractsStore

  objectsNeedTenant: ObjectsNeedTenantStore

  objectsNeedInitial: ObjectsNeedInitialStore

  closingRentalContracts: ContractsStore

  pendingServiceContracts: PendingContractsStore

  settings: ConsultantDashboardSettings | null

  loader: Loader

  actionLoader: Loader

  dashboardStore: DashboardPageStore

  role: UserRole

  congratsCard: number

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.newContractsCount = null
    this.newContractsRent = null
    this.newContractsCommission = null
    this.newContractsList = new NewRentalContractsStore(dashboardStore)
    this.objectsNeedTenant = new ObjectsNeedTenantStore(dashboardStore)
    this.objectsNeedInitial = new ObjectsNeedInitialStore(dashboardStore)
    this.closingRentalContracts = new ContractsStore("rental", dashboardStore)
    this.pendingServiceContracts = new PendingContractsStore(
      "service",
      dashboardStore
    )
    this.settings = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.role = "consultant"
    this.congratsCard = Math.floor(Math.random() * 3)
    makeAutoObservable(this)
  }

  initDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.actionLoader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.actionLoader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      const promises = []
      if (
        this.settings.objectsNeedInitialMap ||
        this.settings.objectsNeedInitialList
      ) {
        promises.push(this.objectsNeedInitial.fetchObjects())
      }

      await callPromisesBySettings(
        this.settings,
        {
          newContractsCount: this.fetchNewContractsCount,
          newContractsRent: this.fetchNewContractsRent,
          newContractsCommission: this.fetchNewContractsCommission,
          newContractsList: this.newContractsList.fetchContracts,
          objectsNeedTenant: this.objectsNeedTenant.fetchObjects,
          closingRentalContracts: this.closingRentalContracts.fetchContracts,
          pendingServiceContracts: this.pendingServiceContracts.fetchContracts,
        },
        promises
      )
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: ConsultantDashboardSettings) => {
    this.loader.startLoading()

    const body = ConsultantDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      newContractsCount: !!visible_blocks.new_rental_contracts_count,
      newContractsCommission:
        !!visible_blocks.new_rental_contracts_commission_fees,
      newContractsRent: !!visible_blocks.new_rental_contracts_month_payment,
      newContractsList: !!visible_blocks.new_rental_contracts,
      objectsNeedTenant: !!visible_blocks.objects_need_tenant,
      objectsNeedInitialList: !!visible_blocks.objects_need_initial_inspection,
      objectsNeedInitialMap:
        !!visible_blocks.objects_need_initial_inspection_map,
      closingRentalContracts: !!visible_blocks.closing_rental_contracts,
      pendingServiceContracts: !!visible_blocks.pending_service_contracts,
    }
  }

  fetchDashboardCount = async (type: ConsultantDashboardCounts) => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        countTypes[type] as WorkIndicatorType,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this[type] = DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  fetchNewContractsCount = async () =>
    this.fetchDashboardCount("newContractsCount")

  fetchNewContractsCommission = async () =>
    this.fetchDashboardCount("newContractsCommission")

  fetchNewContractsRent = async () =>
    this.fetchDashboardCount("newContractsRent")

  resetSettings = () => {
    this.settings = null
  }

  resetCards = () => {
    this.newContractsCount = null
    this.newContractsRent = null
    this.newContractsCommission = null
    this.newContractsList = new NewRentalContractsStore(this.dashboardStore)
    this.objectsNeedTenant = new ObjectsNeedTenantStore(this.dashboardStore)
    this.objectsNeedInitial = new ObjectsNeedInitialStore(this.dashboardStore)
    this.closingRentalContracts = new ContractsStore(
      "rental",
      this.dashboardStore
    )
    this.pendingServiceContracts = new PendingContractsStore(
      "service",
      this.dashboardStore
    )
  }

  resetDashboard = () => {
    this.resetSettings()
    this.resetCards()
  }

  static getDashboardSettingsBody = (
    settings: ConsultantDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      new_rental_contracts_count: settings.newContractsCount,
      new_rental_contracts_month_payment: settings.newContractsRent,
      new_rental_contracts_commission_fees: settings.newContractsCommission,
      new_rental_contracts: settings.newContractsList,
      objects_need_tenant: settings.objectsNeedTenant,
      objects_need_initial_inspection: settings.objectsNeedInitialList,
      objects_need_initial_inspection_map: settings.objectsNeedInitialMap,
      closing_rental_contracts: settings.closingRentalContracts,
      pending_service_contracts: settings.pendingServiceContracts,
    },
  })
}

export default ConsultantDashboardStore
