import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import {
  Paginator,
  SortingFilter,
  Loader,
  MultistepForm,
  getPhoneNumberFromPhoneParams,
  getPhoneParamsFromString,
} from "kui-utils"
import { uploadFiles, uploadNewFile } from "kui-crm"
import { LiteUserModel } from "kui-crm/types"
import { MultipleEntitiesStoreInterface } from "../../types/store/pagination"
import { ClientLiteParams } from "../../types/store/user"
import {
  ClientCompanyCreationFields,
  ClientCreationFields,
  ClientPersonalCreationFields,
} from "../../components/modals/ClientCreationModal/types"
import {
  GetUsersResponse,
  ClientLiteModel,
  PostClientRequest,
  PostPersonalClientRequest,
  PostCompanyClientRequest,
} from "../../types/api/clients"
import ClientsAgent from "../../agent/Users"

export const ClientFormStages = ["type", "info", "agreement"] as const

class ClientsLiteStore implements MultipleEntitiesStoreInterface {
  clients: ClientLiteParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<ClientCreationFields, typeof ClientFormStages>

  constructor(clients?: ClientLiteParams[]) {
    this.clients = clients || []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter()
    this.creationForm = new MultistepForm<
      ClientCreationFields,
      typeof ClientFormStages
    >(null, ClientFormStages)
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    await ClientsLiteStore.fetchAllClients(this, (res) => {
      const mapper = (client: ClientLiteModel) =>
        ClientsLiteStore.getClientLiteParams(client)

      this.clients = this.paginator.getPageResponse<
        ClientLiteModel,
        ClientLiteParams
      >(res, this.clients, mapper)
    })
  }

  createClient = async (data: ClientCreationFields) => {
    await ClientsLiteStore.postClient(this, data, (res) => {
      this.clients = [
        ClientsLiteStore.getClientLiteParams(res),
        ...this.clients,
      ]
    })
    return this.clients[0]
  }

  static fetchAllClients = async (
    clientStore: MultipleEntitiesStoreInterface,
    callback: (res: GetUsersResponse) => void
  ) => {
    const { loader, paginator, filter } = clientStore
    loader.startLoading()

    const [err, res] = await to<GetUsersResponse>(
      ClientsAgent.all(paginator.offset, paginator.limit, filter.filterParams)
    )

    runInAction(() => {
      if (!err && res) {
        callback(res)
      } else {
        loader.setError("fetch clients", err)
      }
      loader.endLoading()
    })
  }

  static postClient = async (
    clientsStore: MultipleEntitiesStoreInterface,
    data: ClientCreationFields,
    callback: (res: ClientLiteModel) => void
  ) => {
    const { creationLoader } = clientsStore
    if (creationLoader) {
      creationLoader.startLoading()

      const body: PostClientRequest = await (data.type === "personal"
        ? ClientsLiteStore.getClientPersonalBody(
            data as ClientPersonalCreationFields,
            creationLoader
          )
        : ClientsLiteStore.getClientCompanyBody(
            data as ClientCompanyCreationFields,
            creationLoader
          ))

      const [err, res] = await to<ClientLiteModel>(ClientsAgent.create(body))

      runInAction(() => {
        if (!err && res) {
          callback(res)
        } else {
          creationLoader.setError("client creation", err)
        }
        creationLoader.endLoading()
      })

      return res?.id
    }

    return undefined
  }

  static getClientPersonalBody = async (
    client: ClientPersonalCreationFields,
    loader: Loader
  ): Promise<PostPersonalClientRequest> => {
    const file = await uploadNewFile(loader, client.privacyPolicy)

    return {
      client_type: "personal",
      first_name: client.firstName,
      last_name: client.lastName,
      middle_name: client.middleName,
      phone: client.phone ? getPhoneNumberFromPhoneParams(client.phone) : "",
      email: client.email,
      privacy_policy_agreement: file!,
      ...(client.note && {
        notes: [{ text: client.note }],
      }),
    }
  }

  static getClientCompanyBody = async (
    client: ClientCompanyCreationFields,
    loader: Loader
  ): Promise<PostCompanyClientRequest> => {
    const [companyInfo, confirmationOfAuthority] = await uploadFiles(loader, [
      client.companyInfo,
      client.confirmationOfAuthority,
    ])

    return {
      client_type: "company",
      company_name: client.companyName,
      first_name: client.firstName,
      last_name: client.lastName,
      middle_name: client.middleName,
      company_position: client.jobTitle,
      phone: client.phone ? getPhoneNumberFromPhoneParams(client.phone) : "",
      email: client.email,
      company_card: companyInfo,
      authority_confirmation: confirmationOfAuthority,
      ...(client.note && {
        notes: [{ text: client.note }],
      }),
    }
  }

  static getClientLiteParams = (client: ClientLiteModel | LiteUserModel) => ({
    id: client.id,
    name: `${client.first_name || ""} ${client.last_name || ""}`,
    avatar: null,
    phone: client.phone ? getPhoneParamsFromString(client.phone) : null,
    email: client.email,
    clientType: client.client_type,
  })
}

export default ClientsLiteStore
