import * as React from "react"
import { observer } from "mobx-react"
import { Box, Caption } from "kui-basic"
import { useState } from "react"
import styled from "@emotion/styled"
import { useForm } from "react-hook-form"
import ApartmentAutocomplete from "../../../../components/entityInputs/ApartmentAutocomplete"
import { ApartmentLiteParams } from "../../../../types/store/apartments"
import { DynamicRoutes } from "../../../../types/route"

function CreateServiceContractsForm() {
  const form = useForm()
  const [selectedApartment, setSelectedApartment] = useState<number | null>(
    null
  )
  const inspectionsTabLink = selectedApartment
    ? DynamicRoutes.inspections(selectedApartment)
    : null

  const handleApartmentSelect = (apartment: ApartmentLiteParams) => {
    if (apartment) setSelectedApartment(apartment.id)
    else setSelectedApartment(null)
  }

  return (
    <Box pr={5} pl={5} pb={3}>
      <ApartmentAutocomplete
        testId="apartment_autocomplete"
        defaultFilter="&with_service_contract=false"
        onSelect={handleApartmentSelect}
        form={form}
      />

      {!!selectedApartment && inspectionsTabLink && (
        <StyledWarning>
          <Caption size="s" weight={500} color="fiftyP">
            To create a contract, you need to{" "}
            <StyledLink href={inspectionsTabLink}>
              create initial appraisal
            </StyledLink>
          </Caption>
        </StyledWarning>
      )}
    </Box>
  )
}

export default observer(CreateServiceContractsForm)

const StyledWarning = styled.div`
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.red.fiftyP};
  margin-top: 16px;
`

const StyledLink = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.brand.main};
`
