export const appraisalStatusLabels = {
  rejected: "Rejected",
  signed: "Accepted",
  notSigned: "Not sign",
}

export const appraisalStatusColors = {
  rejected: "red",
  signed: "green",
  notSigned: "grey",
}
