import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import { Loader, Editor, callPromises } from "kui-utils"
import { ServiceContractModel } from "kui-crm/types"
import NotesStore from "../../../store/notes/Notes"
import ServiceContractInfoStore from "../../../store/shared/contracts/ServiceContractInfoStore"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import ServiceContractAgent from "../../../agent/ServiceContract"
import HostStore from "../../../store/Root"
import ServiceContractsAgent from "../../../agent/ServiceContracts"
import { SCInfoFormFields } from "../components/SCInfoFields/types"
import ContractChangesStore from "../../../store/templates/ContractChangesStore"

class ServiceContractPageStore {
  contractInfoStore: ServiceContractInfoStore

  documentsStore: DocumentsStore

  notesStore: NotesStore | null

  editor: Editor

  loader: Loader

  actionLoader: Loader

  contractChangesStore: ContractChangesStore

  lastOpenPeriod: DateTime | null

  constructor(hostStore: HostStore) {
    this.contractInfoStore = new ServiceContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(ServiceContractAgent)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.contractChangesStore = new ContractChangesStore(
      "service",
      this.actionLoader,
      this.contractInfoStore
    )
    this.lastOpenPeriod = null
    makeAutoObservable(this)
  }

  initSCPage = async (id: number) => {
    this.updateServiceContractPage()

    this.loader.startLoading()

    await callPromises([
      this.fetchServiceContract(id),
      this.documentsStore.fetchDocuments(id),
      this.contractChangesStore.fetchPendingChanges(id),
      this.contractChangesStore.fetchAppliedChanges(id),
    ])

    this.loader.endLoading()
  }

  fetchServiceContract = async (id: number) => {
    this.loader.startLoading()

    const [err, res] = await to<ServiceContractModel>(
      ServiceContractAgent.getById(id)
    )
    runInAction(() => {
      if (!err && res) {
        if (res.is_auto_filled)
          this.contractInfoStore.updateServiceContractInfo(res)
        else this.contractInfoStore.updateServiceContract(res)
        this.notesStore = new NotesStore("service-contracts", res.id)
      } else {
        this.loader.setError("fetch service contract")
      }
      this.loader.endLoading()
    })
  }

  deleteServiceContract = async (id: number) => {
    this.actionLoader.startLoading()

    const [err] = await to(ServiceContractsAgent.delete(id))
    if (err) {
      this.actionLoader.setError("service contract removal")
    }
    this.actionLoader.endLoading()

    return err
  }

  addPendingChange = async (data: Partial<SCInfoFormFields>) => {
    const contractId = this.contractInfoStore.id
    if (contractId) {
      this.actionLoader.startLoading("rental contract changes")

      await this.contractInfoStore.addPendingChange(data)
      await this.contractChangesStore.fetchPendingChanges(contractId)

      this.actionLoader.endLoading()
    }
  }

  updateServiceContractPage = () => {
    this.contractInfoStore = new ServiceContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(ServiceContractAgent)
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.editor = new Editor()
  }
}

export default ServiceContractPageStore
