import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useSCsStore from "./store"
import usePaginationPage from "../../hooks/usePaginationPage"
import ServiceContractsTable from "./components/ServiceContractsTable"

function ServiceContractsPage() {
  const scStore = useSCsStore()
  const { paginator, filter, fetchAll, loader } = scStore

  usePaginationPage(paginator, fetchAll, filter)

  return (
    <>
      <ServiceContractsTable />

      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(ServiceContractsPage)
