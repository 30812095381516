import React from "react"
import { HomeIcon, RubleHistoryIcon, RubleIcon } from "kui-icon"
import { addToArrayByCondition } from "kui-utils"
import { Routes } from "../../../../../types/route"
import { LinkCardProps } from "../../../components/common/DashboardLinkCard/types"
import SalesManagerDashboardStore from "../../../store/variants/SalesManagerDashboardStore"
import { DashboardNumberCardProps } from "../../../components/common/DasboardNumberCard/types"
import ContractsIncreasedRentCard from "../../../components/cards/ContractsIncreasedRentCard"
import ObjectsWithoutAppraisalCard from "../../../components/cards/ObjectsWithoutAppraisalCard"
import NewObjectsCard from "../../../components/cards/NewObjectsCard"
import RejectedAppraisalsCard from "../../../components/cards/RejectedInspectionsCard"

export const getIncreasedRentLinkCards = (
  salesManagerDashboard: SalesManagerDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(
    salesManagerDashboard.increasedRentObjects !== null,
    {
      label: "Objects increased rent",
      icon: <HomeIcon />,
      link: `${Routes.tenantAppraisalsRegistry}?sort=creation_date`,
      ...salesManagerDashboard.increasedRentObjects,
    }
  ),
]

export const getNewObjectsLinkCards = (
  salesManagerDashboard: SalesManagerDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(
    salesManagerDashboard.increasedRentObjects !== null,
    {
      label: "New objects",
      icon: <HomeIcon />,
      link: `${Routes.rental_contracts}?sort=creation_date`,
      ...salesManagerDashboard.newObjectsCount,
    }
  ),
]

export const getNewObjectsNumberCards = (
  salesManagerDashboard: SalesManagerDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(salesManagerDashboard.newObjectsRent !== null, {
    label: "Total rent",
    icon: <RubleIcon />,
    unit: "₽",
    ...salesManagerDashboard.newObjectsRent,
  }),
  ...addToArrayByCondition(
    salesManagerDashboard.newObjectsCommission !== null,
    {
      label: "Commission fees",
      icon: <RubleIcon />,
      unit: "₽",
      ...salesManagerDashboard.newObjectsCommission,
    }
  ),
]

export const getIncreasedRentNumberCards = (
  salesManagerDashboard: SalesManagerDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(
    salesManagerDashboard.increasedRentPercent !== null,
    {
      icon: <RubleHistoryIcon />,
      unit: "%",
      ...salesManagerDashboard.increasedRentPercent,
    }
  ),
  ...addToArrayByCondition(salesManagerDashboard.increasedRentPrice !== null, {
    icon: <RubleIcon />,
    unit: "₽",
    ...salesManagerDashboard.increasedRentPrice,
  }),
]

export const getImportantInfoListCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => [
  ...addToArrayByCondition(
    salesManagerDashboard.objectsWithoutAppraisal.value !== null,
    {
      key: "objectsWithoutAppraisal",
      card: (
        <ObjectsWithoutAppraisalCard
          objectsStore={salesManagerDashboard.objectsWithoutAppraisal}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    salesManagerDashboard.rejectedAppraisals.value !== null,
    {
      key: "rejectedAppraisals",
      card: (
        <RejectedAppraisalsCard
          inspectionsStore={salesManagerDashboard.rejectedAppraisals}
        />
      ),
    }
  ),
]

export const getIncreasedRentListCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => [
  ...addToArrayByCondition(
    salesManagerDashboard.contractsIncreasedRent.value !== null,
    {
      key: "contractsIncreasedRent",
      card: (
        <ContractsIncreasedRentCard
          contractsStore={salesManagerDashboard.contractsIncreasedRent}
        />
      ),
    }
  ),
]

export const getNewObjectsListCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => [
  ...addToArrayByCondition(
    salesManagerDashboard.newObjectsList.value !== null,
    {
      key: "newObjectsList",
      card: (
        <NewObjectsCard objectsStore={salesManagerDashboard.newObjectsList} />
      ),
    }
  ),
]

export const getImportantInfoCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => ({
  listCards: getImportantInfoListCards(salesManagerDashboard),
})

export const getIncreasedRentCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => ({
  linkCards: getIncreasedRentLinkCards(salesManagerDashboard),
  numberCards: getIncreasedRentNumberCards(salesManagerDashboard),
  listCards: getIncreasedRentListCards(salesManagerDashboard),
})

export const getNewObjectsCards = (
  salesManagerDashboard: SalesManagerDashboardStore
) => ({
  linkCards: getNewObjectsLinkCards(salesManagerDashboard),
  numberCards: getNewObjectsNumberCards(salesManagerDashboard),
  listCards: getNewObjectsListCards(salesManagerDashboard),
})
