import React from "react"

const ChartBarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.25342 0H12.2534V19H8.25342V0Z" fill="#C4C4C4" />
    <path d="M0.253418 3.16667H4.25342V19H0.253418V3.16667Z" fill="#C4C4C4" />
    <path d="M24.2534 6.9663H28.2534V18.9996H24.2534V6.9663Z" fill="#C4C4C4" />
    <path d="M16.2534 3.16667H20.2534V19H16.2534V3.16667Z" fill="#C4C4C4" />
  </svg>
)

export default ChartBarIcon
