import React from "react"
import { observer } from "mobx-react"
import { SCFieldsHeaderProps } from "../SCFieldsHeader/types"
import ContractEditingButtons from "../../../../components/common/ContractEditingButtons"
import useSCStore from "../../store"
import { getDirtyValues } from "../../../../utils/service/form"

const SCEditingButtons = ({ form }: SCFieldsHeaderProps) => {
  const { editor, addPendingChange, contractChangesStore, contractInfoStore } =
    useSCStore()

  const handleEdit = (data: any) => {
    const dirtyFields = getDirtyValues(
      form.control._formState.dirtyFields,
      data
    )
    const formattedFields = {
      ...dirtyFields,
      changesFile: data.changesFile,
      changesDate: data.changesDate,
    }

    if (contractInfoStore.status === "Pending")
      contractInfoStore.patchServiceContractInfo(formattedFields)
    else addPendingChange(formattedFields)
    editor.endEditing()

    if (contractInfoStore.status !== "Pending") form.reset()
  }

  return (
    <ContractEditingButtons
      editor={editor}
      form={form}
      handleEdit={handleEdit}
      handleActivate={contractInfoStore.activateServiceContract}
      status={contractInfoStore.status}
      contractChangesStore={contractChangesStore}
      apartmentId={contractInfoStore.apartment?.id}
      canActivate={contractInfoStore.canActivate}
      canEdit={contractInfoStore.canEdit}
      activateCondition={contractInfoStore.activateCondition}
    />
  )
}

export default observer(SCEditingButtons)
