import React from "react"
import { Grid } from "kui-basic"
import { DateTime } from "luxon"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import EditContractModal from "../../modals/EditContractModal"
import { ContractEditingButtonsProps } from "./types"
import EditButton from "../../ui/EditButton"
import ActivateContractButton from "../ActivateContractButton"
import TooltipByCondition from "../TooltipByCondition"
import { DynamicRoutes } from "../../../types/route"
import { noAccessMessage } from "../../../utils/content/constants"

const ContractEditingButtons = (props: ContractEditingButtonsProps) => {
  const { editor, status, contractChangesStore, canActivate, canEdit } = props
  const { form, handleEdit, handleActivate, apartmentId, activateCondition } =
    props
  const [isModalOpen, handleOpen, handleClose] = useToggle()
  const expensesLink = apartmentId
    ? DynamicRoutes.summaryExpenses(apartmentId)
    : ""

  const startEditing = () => {
    if (status === "Pending") editor.startEditing()
    else handleOpen()
  }

  return (
    <>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TooltipByCondition
              condition={
                status === "Pending" &&
                typeof canActivate === "boolean" &&
                !canActivate
              }
              message={
                activateCondition === "closedPeriods" ? (
                  <>
                    <StyledLink href={expensesLink}>Close</StyledLink> past
                    billing periods
                  </>
                ) : (
                  noAccessMessage
                )
              }
            >
              <ActivateContractButton
                status={status}
                handleSubmit={handleActivate}
              />
            </TooltipByCondition>
          </Grid>

          <Grid item>
            <TooltipByCondition condition={!canEdit} message={noAccessMessage}>
              <EditButton
                disabled={contractChangesStore.hasEditingChange}
                isEditing={editor.isEditing}
                onSaveHandler={form.handleSubmit(handleEdit)}
                onEditHandler={startEditing}
              />
            </TooltipByCondition>
          </Grid>
        </Grid>
      </Grid>
      <EditContractModal
        open={isModalOpen}
        handleClose={handleClose}
        editor={editor}
        form={form}
        usedDates={contractChangesStore.usedPendingDates}
        lastOpenPeriod={DateTime.now()}
      />
    </>
  )
}

export default observer(ContractEditingButtons)

const StyledLink = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.brand.main};
`
