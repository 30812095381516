import React from "react"
import { HomeIcon } from "kui-icon"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { ObjectsNeedTenantProps } from "./types"
import ObjectNeedTenantRow from "./ObjectNeedTenantRow"
import useDashboardStore from "../../../store"

const ObjectsNeedTenantCard = (props: ObjectsNeedTenantProps) => {
  const { objectsStore } = props
  const { consultantDashboard } = useDashboardStore()
  const { value, loader, paginator, fetchObjects, objects } = objectsStore

  return (
    <DashboardListCard
      withCongrats={consultantDashboard.congratsCard === 1}
      value={value}
      label="Objects need tenant"
      icon={<HomeIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchObjects}
    >
      {objects.map((object) => (
        <ObjectNeedTenantRow key={object.id} object={object} />
      ))}
    </DashboardListCard>
  )
}

export default observer(ObjectsNeedTenantCard)
