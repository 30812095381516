import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import NoteBlock from "../../../../../components/common/NoteBlock"
import ContractsTable from "../../../../../components/common/ContractsTable"
import Container from "../../../../../components/ui/Container"
import useApartmentStore from "../../../store"
import ApartmentDocumentsRow from "./ApartmentDocumentsRow"
import ApartmentOverviewInfo from "./ApartmentOverviewInfo"
import Loading from "../../../../../components/common/Loading"

function ApartmentOverviewTab() {
  const { overviewStore, setEditPermission } = useApartmentStore()

  useEffect(() => {
    setEditPermission("objectOverviewEdit")
    overviewStore.initOverviewTab()
  }, [overviewStore])

  if (overviewStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <Container>
        <ApartmentOverviewInfo />
        <ApartmentDocumentsRow />
      </Container>
      <ContractsTable
        rentalContracts={overviewStore.rentalContracts}
        serviceContracts={overviewStore.serviceContracts}
      />
      <Container>
        {overviewStore.notesStore && (
          <NoteBlock notesStore={overviewStore.notesStore} />
        )}
      </Container>
      <LoaderState loader={overviewStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentOverviewTab)
