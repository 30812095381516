import React from "react"
import { Button, Caption, Grid } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import { useToggle } from "kui-utils"
import { EmptyApartmentDocumentRowProps } from "./types"
import ApartmentDocumentCreationModal from "../ApartmentDocumentCreationModal"
import ProtectedNode from "../../../../../../components/common/ProtectedNode"

const EmptyApartmentDocumentRow = (props: EmptyApartmentDocumentRowProps) => {
  const { variant, disabled } = props
  const [isOpen, handleOpen, handleClose] = useToggle()

  return (
    <StyledGrid container alignItems="center">
      <StyledLabel size="l" weight={600}>
        {_.capitalize(variant)}
      </StyledLabel>
      <ProtectedNode withTooltip permission="objectOverviewEdit">
        <Button
          variant="orange"
          size="xs"
          startIcon={<PlusIcon />}
          onClick={handleOpen}
          disabled={disabled}
        >
          Add {variant}
        </Button>
      </ProtectedNode>

      <ApartmentDocumentCreationModal
        variant={variant}
        open={isOpen}
        handleClose={handleClose}
      />
    </StyledGrid>
  )
}

export default EmptyApartmentDocumentRow

const StyledLabel = styled(Caption)`
  width: 152px;
`

const StyledGrid = styled(Grid)`
  height: 64px;
`
