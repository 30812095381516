import requests from "./index"
import { PatchPromotionContentBodyRequest } from "../pages/ApartmentPage/pages/PromotionPage/types/api/promotionContentAPI"
import { PatchChannelsBodyRequest } from "../pages/ApartmentPage/pages/PromotionPage/types/api/promotionChannelsAPI"

const ApartmentPromotionAgent = {
  getPromotionContent: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/promotion/content`),
  patchPromotionContent: (
    apartmentId: number,
    data: PatchPromotionContentBodyRequest
  ) => requests.patch(`/apartments/${apartmentId}/promotion/content`, data),
  getPromotionChannels: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/promotion/channels`),
  patchPromotionChannels: (
    apartmentId: number,
    data: PatchChannelsBodyRequest
  ) => requests.patch(`/apartments/${apartmentId}/promotion/channels`, data),
  getVolumeOfApartments: (startDate: string, endDate: string) =>
    requests.get(
      `/ml-price/report?start_date=${startDate}&end_date=${endDate}`
    ),
  getRentalContractPrices: (
    contractId: number,
    startDate: string,
    endDate: string
  ) =>
    requests.get(
      `/rental-contracts/${contractId}/month-payment-history?start_date=${startDate}&end_date=${endDate}`
    ),
}

export default ApartmentPromotionAgent
