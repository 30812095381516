import React from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import { TenantOfferStepFields, TenantOfferStepProps } from "./types"
import TenantOfferStepSchema from "./schema"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"
import { tenantOfferFields, tenantValuationFields } from "./fields"
import InputsModalGroupWithTitle from "../../../../../../common/InputsModalGroupWithTitle"

const TenantOfferStep = ({ handleSubmit }: TenantOfferStepProps) => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const basedOnInitial = formStore.fields?.basedOnInitial

  const form = useForm<TenantOfferStepFields>({
    defaultValues: {
      ...formStore.fields,
      advertisingPeriod: formStore.fields?.advertisingPeriod ?? 4,
    },
    resolver: yupResolver(TenantOfferStepSchema),
  })

  const handleFormSubmit = (data: TenantOfferStepFields) => {
    formStore.updateFormFields(data)
    if (basedOnInitial) formStore.nextStep()
    else handleSubmit()
  }

  return (
    <FormWrapper
      form={form}
      onSubmit={form.handleSubmit(handleFormSubmit)}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      label={basedOnInitial ? "Next" : "Create appraisal"}
      variant={basedOnInitial ? "next" : "create"}
    >
      <Grid container spacing={2}>
        {tenantValuationFields.map(({ size, ...field }) => (
          <Grid item xs={size || 12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
      <InputsModalGroupWithTitle
        title="Offer"
        form={form}
        fields={tenantOfferFields}
      />
    </FormWrapper>
  )
}

export default observer(TenantOfferStep)
