import * as React from "react"
import styled from "@emotion/styled"
import { Button, Box, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"
import InputSearch from "../../ui/InputSearch"
import Container from "../../ui/Container"
import { SearchBlockProps } from "./types"
import ProtectedNode from "../ProtectedNode"
import SectionHeader from "../SectionHeader"

function SearchBlock<TFormValues extends object>({
  label,
  buttonHandler,
  startComponent,
  className,
  permission,
  ...inputProps
}: SearchBlockProps<TFormValues>) {
  return (
    <StyledContainer className={className}>
      <Box pb={1} pt={3}>
        <Grid container alignItems="center" justify="space-between">
          <SectionHeader label={label} />

          <Grid item>
            <Grid container wrap="nowrap">
              {startComponent}
              <InputSearch {...inputProps} />
              <ProtectedNode withTooltip permission={permission}>
                <StyledAddButton
                  isCircle
                  data-testid="open_modal"
                  onClick={buttonHandler}
                  size="s"
                  variant="orange"
                >
                  <PlusIcon />
                </StyledAddButton>
              </ProtectedNode>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledContainer>
  )
}

export default SearchBlock

const StyledContainer = styled(Container)`
  position: sticky;
  top: 64px;
  background-color: ${({ theme }) => theme.palette.grey.zero};
  z-index: 11;
`

const StyledAddButton = styled(Button)`
  margin-left: 12px;
`
