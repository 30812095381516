import { DashboardCardColorsParams, LinkCardColors } from "./types"

export const StatusesColors = {
  ok: "green",
  warning: "orange",
  alert: "red",
}

export const getCardColor = (
  params: DashboardCardColorsParams
): LinkCardColors => {
  const { value, compareVariant, variant, status } = params

  if (status) return StatusesColors[status] as LinkCardColors

  const errorColor = variant === "error" ? "red" : "orange"
  return value != null &&
    (compareVariant === "greater" ? value > 0 : value <= 0)
    ? errorColor
    : "green"
}
