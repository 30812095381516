import React from "react"
import { Button, Grid } from "kui-basic"
import { observer } from "mobx-react"
import useExpensesStore from "../../../../store"
import useApartmentStore from "../../../../../../store"
import ProtectedNode from "../../../../../../../../components/common/ProtectedNode"

const CalcDepositFileButton = () => {
  const { isArchived } = useApartmentStore()
  const { summaryStore, overviewStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const disabled =
    isArchived ||
    !(
      selectedPeriod?.isLastMonthOfContract &&
      !selectedPeriod?.isDepositCalculated
    )

  const handleClick = () => {
    if (overviewStore.id && selectedPeriod?.id) {
      summaryStore.calcDepositAndRefresh(
        overviewStore.id,
        selectedPeriod?.rentalContract?.id,
        selectedPeriod.id
      )
    }
  }

  return (
    <Grid item>
      <ProtectedNode withTooltip permission="expensesSummaryEdit">
        <Button
          disabled={disabled}
          variant="white"
          size="s"
          onClick={handleClick}
        >
          Deposit calculation
        </Button>
      </ProtectedNode>
    </Grid>
  )
}

export default observer(CalcDepositFileButton)
