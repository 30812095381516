import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { clearIndexStores } from "kui-utils"
import useApartmentStore from "../../../store"
import { CreationInspectionFormProps } from "./types"
import InspectionCommonStep from "../../../../../components/forms/apartment/InspectionCreationForm/InspectionCommonStep"
import { DynamicRoutes } from "../../../../../types/route"
import { inspectionsSteps } from "../../../../../components/forms/apartment/InspectionCreationForm/content"
import {
  formFieldsName,
  formStateName,
} from "../../../../../utils/content/constants"

const InspectionCreationForm = (props: CreationInspectionFormProps) => {
  const { type, basedOn, handleClose, basedOnInitial } = props
  const { inspectionsStore, overviewStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const navigate = useNavigate()

  const handleCreate = (inspectionId?: number | null) => {
    if (inspectionId) {
      const inspectionLink = DynamicRoutes.inspection(
        overviewStore.id!,
        inspectionId
      )
      navigate(inspectionLink)
    }
    formStore.resetForm()
    clearIndexStores(inspectionsStore.indexDB, [formStateName, formFieldsName])
  }

  useEffect(() => {
    if (basedOn) formStore.updateFormFields({ basedOn })
    if (basedOnInitial) formStore.updateFormFields({ basedOnInitial })
    if (type) formStore.updateFormFields({ type })
  }, [basedOn])

  useEffect(() => {
    formStore.updateFormFields({
      hasRentalContract: !!overviewStore.actualRentalContract,
      hasServiceContract: !!overviewStore.actualServiceContract,
    })
  }, [])

  const InspectionSteps =
    inspectionsSteps[
      (formStore.fields?.type || "initial") as keyof typeof inspectionsSteps
    ]

  return formStore.step === 1 ? (
    <InspectionCommonStep type={type} />
  ) : (
    <InspectionSteps
      handleCreate={handleCreate}
      shift={1}
      handleClose={handleClose}
    />
  )
}

export default observer(InspectionCreationForm)
