import React from "react"
import { observer } from "mobx-react"
import PaginationPage from "../../../../../components/common/PaginationPage"
import SortingTable from "../../../../../components/common/SortingTable"
import useRegistriesStore from "../../../store"
import inspectionHeadCells from "./content"
import ImpairmentsRegistryStore from "../../../store/variants/ImpairmentsRegistryStore"
import ImpairmentsRegistryRow from "./ImpairmentsRegistryRow"

const ImpairmentsRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { impairments } = activeRegistryStore as ImpairmentsRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={inspectionHeadCells}
        store={activeRegistryStore}
        offset={188}
      >
        {impairments?.map((inspection, key) => (
          <ImpairmentsRegistryRow
            key={inspection.id}
            inspection={inspection}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(ImpairmentsRegistryTable)
