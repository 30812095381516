import React, { useState } from "react"
import { Box } from "kui-basic"
import MenuPanel from "../../../../../components/ui/MenuPanel"
import useTabIndex from "../../../../../hooks/useTabIndex"
import ApartmentDocumentsBlock from "./ApartmentDocumentsBlock"
import DocumentsTabSettings from "./DocumentsTabSettings"
import ExpensesAttachmentsTab from "./ExpensesAttachmentsTab"
import ProtectedTabs from "../../../../../components/common/ProtectedTabs"
import { ProtectedTabParams } from "../../../../../components/common/ProtectedTabs/types"

const tabs: ProtectedTabParams[] = [
  { label: "All" },
  { label: "Overview", permission: "objectOverviewView" },
  { label: "Ownership", permission: "objectOwnershipView" },
  { label: "Inspections", permission: "objectInspectionsView" },
  { label: "Descriptions", permission: "objectDescriptionView" },
  { label: "Expenses", permission: "objectExpensesView" },
]

function ApartmentDocumentsTab() {
  const tabPanels = [
    <ApartmentDocumentsBlock tab="all" />,
    <ApartmentDocumentsBlock tab="overview" />,
    <ApartmentDocumentsBlock tab="ownership" />,
    <ApartmentDocumentsBlock tab="inspections" />,
    <ApartmentDocumentsBlock tab="descriptions" />,
    <ExpensesAttachmentsTab />,
  ]
  const paths = [
    "?tab=documents&subtab=all",
    "?tab=documents&subtab=overview",
    "?tab=documents&subtab=ownership",
    "?tab=documents&subtab=inspections",
    "?tab=documents&subtab=descriptions",
    "?tab=documents&subtab=expenses",
  ]
  const defaultActiveTab = useTabIndex(paths, "subtab")
  const [activeTab, setActiveTab] = useState(+defaultActiveTab)

  return (
    <Box pt={3} pb={3}>
      <ProtectedTabs tabs={tabs} tabPanels={tabPanels}>
        <MenuPanel
          activeTab={+defaultActiveTab}
          paths={paths}
          label="Documents"
          handleChange={setActiveTab}
          endComponent={<DocumentsTabSettings activeTab={activeTab} />}
        />
      </ProtectedTabs>
    </Box>
  )
}

export default ApartmentDocumentsTab
