import * as React from "react"
import SignInPage from "../pages/SignInPage"
import { RouteAuthTypes, RouteParams, Routes } from "../types/route"
import ServiceContractPage from "../pages/ServiceContractPage"
import CompaniesPage from "../pages/CompaniesPage"
import CompanyPage from "../pages/CompanyPage"
import FunctionsPage from "../pages/FunctionsPage"
import TariffPage from "../pages/TariffPage"
import ContractsPage from "../pages/ContractsPage"
import MeterPage from "../pages/MeterPage"
import ApartmentPage from "../pages/ApartmentPage"
import RentalContractPage from "../pages/RentalContractPage"
import ApartmentsPage from "../pages/ApartmentsPage"
import ClientPage from "../pages/ClientPage"
import ClientsPage from "../pages/ClientsPage"
import DashboardPage from "../pages/DashoboardPage"

const routes: Array<RouteParams> = [
  {
    title: "Dashboard",
    path: Routes.main,
    content: <DashboardPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "dashboardView",
  },
  {
    path: Routes.signIn,
    content: <SignInPage />,
    auth: RouteAuthTypes.loggedOut,
  },
  {
    path: Routes.client,
    content: <ClientPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "clientsView",
  },
  {
    title: "Clients",
    path: Routes.clients,
    content: <ClientsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "clientsView",
  },
  {
    path: Routes.apartment,
    content: <ApartmentPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "objectsView",
  },
  {
    title: "Objects",
    path: Routes.apartments,
    content: <ApartmentsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "objectsView",
  },
  {
    path: Routes.company,
    content: <CompanyPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "companyView",
  },
  {
    title: "Companies",
    path: Routes.companies,
    content: <CompaniesPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "companiesView",
  },
  {
    path: Routes.service_contract,
    content: <ServiceContractPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "serviceContractsView",
  },
  {
    path: Routes.rental_contract,
    content: <RentalContractPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "rentalContractsView",
  },
  {
    path: `${Routes.contracts}/*`,
    content: <ContractsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "contractsView",
  },
  {
    title: "Contracts",
    path: Routes.contracts,
    content: <ContractsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "contractsView",
  },
  {
    path: Routes.electricityTariffsGroup,
    content: <TariffPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "tariffGroup",
  },
  {
    path: Routes.waterTariffsGroup,
    content: <TariffPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "tariffGroup",
  },
  {
    path: Routes.gasTariffsGroup,
    content: <TariffPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "tariffGroup",
  },
  {
    path: Routes.heatingTariffsGroup,
    content: <TariffPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "tariffGroup",
  },
  {
    path: Routes.electricityMeteringDevice,
    content: <MeterPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "meter",
  },
  {
    path: Routes.waterMeteringDevice,
    content: <MeterPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "meter",
  },
  {
    path: Routes.gasMeteringDevice,
    content: <MeterPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "meter",
  },
  {
    path: Routes.heatingMeteringDevice,
    content: <MeterPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "meter",
  },
  {
    path: `${Routes.functions}/*`,
    content: <FunctionsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "functionsView",
  },
  {
    title: "Functions",
    path: Routes.functions,
    content: <FunctionsPage />,
    auth: RouteAuthTypes.loggedIn,
    permission: "functionsView",
  },
]

export default routes
