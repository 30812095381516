import React from "react"
import styled from "@emotion/styled"
import _ from "lodash"
import { observer } from "mobx-react"
import { Caption, Button, Box, Grid } from "kui-basic"
import { CalendarIcon, EditIcon } from "kui-icon"
import TwoCaptionsBlock from "../../../../../../../../components/common/TwoCaptionsBlock"
import TypographyMoney from "../../../../../../../../components/common/TypographyMoney"
import { ApartmentPaymentTableLineProps } from "./types"
import useExpensesStore from "../../../../store"
import UserBadge from "../../../../../../../../components/common/UserBadge"
import { TransactionTypeValues } from "../../../../../../../../utils/content/listsOptions"
import useApartmentStore from "../../../../../../store"
import ProtectedNode from "../../../../../../../../components/common/ProtectedNode"

function ExpensesPaymentTableLine({
  payment,
  onClick,
}: ApartmentPaymentTableLineProps) {
  const { isArchived } = useApartmentStore()
  const { periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const invoiceNumber = payment.invoice ? `№ ${payment.invoice.number}` : "-"
  const contractNumber = payment.contract ? `№ ${payment.contract.number}` : "-"
  const transactionType =
    TransactionTypeValues.find(
      (option) => option.value === payment.transactionType
    )?.label || "-"
  const disabledEditButton = !(selectedPeriod?.canBeEdited && !isArchived)

  return (
    <StyledWrapper
      data-testid="payment_row"
      container
      wrap="nowrap"
      alignItems="center"
    >
      <StyledTransaction
        bottom="Transaction type"
        top={transactionType}
        bottomSize="xs"
        topSize="s"
        bottomWeight={400}
        spacing={0}
      />
      <StyledUserBadge
        user={payment.payer}
        role={_.capitalize(payment.payer.role!)}
        data-testid="payment_payer"
      />
      <StyledInvoice
        bottom={
          payment.payer.role === "tenant" ? "Invoice number" : "Contract number"
        }
        top={payment.payer.role === "tenant" ? invoiceNumber : contractNumber}
        bottomSize="xs"
        topSize="s"
        bottomWeight={400}
        spacing={0}
      />
      <StyledRightContentWrapper>
        <Grid container alignItems="center" wrap="nowrap">
          <StyledDateWrapper container alignItems="center">
            <StyledIcon width={14} height={15} />
            <Caption
              data-testid="payment_date"
              size="s"
              weight={500}
              color="fiftyP"
            >
              {payment.date?.toFormat("dd MMM") || "-"}
            </Caption>
          </StyledDateWrapper>
          <StyledMoneyWrapper>
            <TypographyMoney
              prefix="+"
              weight={600}
              size="s"
              value={payment.amount}
              testId="payment_amount"
            />
          </StyledMoneyWrapper>
          <Box pl={2}>
            <ProtectedNode withTooltip permission="expensesSummaryEdit">
              <Button
                isCircle
                data-testid="payment_edit_btn"
                onClick={onClick}
                size="xs"
                variant="transparent"
                disabled={disabledEditButton}
              >
                <EditIcon width={16} height={16} />
              </Button>
            </ProtectedNode>
          </Box>
        </Grid>
      </StyledRightContentWrapper>
    </StyledWrapper>
  )
}

export default observer(ExpensesPaymentTableLine)

const StyledMoneyWrapper = styled.div`
  width: 144px;
  text-align: right;
`

const StyledDateWrapper = styled(Grid)`
  width: 95px;
`

const StyledIcon = styled(CalendarIcon)`
  margin-right: 9px;
`

const StyledRightContentWrapper = styled.div`
  margin-left: auto;
`

const StyledWrapper = styled(Grid)`
  max-height: 57px;
  padding: 12px 12px 12px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  &:last-of-type {
    border-bottom: 1px solid transparent;
  }
  & + form {
    border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  & + div {
    border-top: 1px solid transparent;
  }
`

const StyledInvoice = styled(TwoCaptionsBlock)`
  min-width: 200px;
  padding-right: 16px;
`

const StyledTransaction = styled(TwoCaptionsBlock)`
  min-width: 190px;
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  margin-right: 16px;
  padding: 0 16px 0 14px;
`

const StyledUserBadge = styled(UserBadge)`
  min-width: 264px;
  max-width: 264px;
  width: fit-content;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
