import React from "react"
import styled from "@emotion/styled"
import { GreyColors, Button, ButtonThemes, ButtonSizes } from "kui-basic"
import { OpenIcon } from "kui-icon"
import { Link } from "kui-crm"
import { css } from "@emotion/react"
import {
  LinkButtonLabelColor,
  LinkButtonProps,
  LinkButtonStylesProps,
} from "./types"

function LinkButton({
  children,
  href,
  labelColor,
  disabled,
  ...other
}: LinkButtonProps) {
  const buttonProps = {
    size: "xs" as ButtonSizes,
    variant: "backgroundWithBorder" as ButtonThemes,
    endIcon: <OpenIcon />,
    labelColor: labelColor as LinkButtonLabelColor,
    children,
    ...other,
  }

  if (disabled) {
    return <StyledButton disabled {...buttonProps} />
  }

  return (
    <StyledLink stopPropagation href={href}>
      <StyledButton {...buttonProps} />
    </StyledLink>
  )
}

LinkButton.defaultProps = {
  labelColor: "seventy" as const,
}

export default LinkButton

const withLabelColor = ({ theme, labelColor }: LinkButtonStylesProps) =>
  !!labelColor &&
  css`
    color: ${theme!.palette.grey[labelColor as keyof GreyColors]} !important;
  `

const StyledButton = styled(Button)<LinkButtonStylesProps>`
  min-width: 104px !important;
  padding: 0 8px 0 12px !important;
  justify-content: space-between;
  max-width: 100%;
  p {
    ${withLabelColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    font-weight: 500;
  }
  &:disabled {
    p {
      color: ${({ theme }) => theme.palette.grey.disabled} !important;
    }
  }
  span {
    margin-left: 4px;
    padding-right: 4px;
  }
`

const StyledLink = styled(Link)`
  width: fit-content;
`
