import React from "react"
import { Divider } from "kui-basic"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { LoaderState } from "kui-crm"
import Container from "../../../../../../../../components/ui/Container"
import PromotionFeatures from "./PromotionFeatures"
import PurposeOfPayments from "./PurposeOfPayments"
import PromotionDescriptionForm from "../../../../forms/PromotionDescriptionForm"
import usePromotionStore from "../../../../store"
import { PromotionDescriptionFormFields } from "../../../../forms/PromotionDescriptionForm/types"
import PromotionDescriptionHeader from "./PromotionDescriptionHeader"
import ContractConditions from "./ContractConditions"

const PromotionDescription = () => {
  const { contentStore } = usePromotionStore()
  const form = useForm<PromotionDescriptionFormFields>({
    defaultValues: {
      ...contentStore.descriptionStore,
    },
  })

  return (
    <Container>
      <Divider />
      <PromotionDescriptionHeader form={form} />
      <PromotionFeatures />
      <ContractConditions />
      <PurposeOfPayments />

      <PromotionDescriptionForm form={form} />

      <LoaderState loader={contentStore.descriptionStore.loader} />
    </Container>
  )
}

export default observer(PromotionDescription)
