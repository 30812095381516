import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import ApartmentChartLegend from "../ApartmentChartLegend"
import { chartLegends, chartLegendsWithIcons } from "./content"

const ApartmentChartLegends = () => (
  <StyledWrapper container spacing={2} direction="column">
    <Grid item xs={12} container spacing={5}>
      {chartLegends.map((legend) => (
        <ApartmentChartLegend key={legend.label} {...legend} />
      ))}
    </Grid>

    <Grid item xs={12} container spacing={5}>
      {chartLegendsWithIcons.map((legend) => (
        <ApartmentChartLegend key={legend.label} {...legend} />
      ))}
    </Grid>
  </StyledWrapper>
)

export default ApartmentChartLegends

const StyledWrapper = styled(Grid)`
  padding-left: 88px;
  padding-top: 16px;
`
