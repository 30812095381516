import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { ImpairmentsStatusProps } from "./types"
import { impairmentsStatusColors } from "./content"
import StatusButton from "../../../common/StatusButton"
import { StatusButtonColors } from "../../../common/StatusButton/types"

const ImpairmentsStatus = (props: ImpairmentsStatusProps) => {
  const { impairments } = props

  return (
    <StyledButton
      inspection={impairments}
      label={_.capitalize(impairments.status)}
      variant={
        impairmentsStatusColors[impairments.status] as StatusButtonColors
      }
    />
  )
}

export default ImpairmentsStatus

const StyledButton = styled(StatusButton)`
  width: 150px;
`
