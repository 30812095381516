import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { LoadingWithSparkles as Loading } from "kui-crm"
import {
  getImportantInfoCards,
  getIncreasedRentCards,
  getNewObjectsCards,
} from "./content"
import useDashboardStore from "../../../store"
import DashboardCardsGroup from "../../../components/common/DashboardCardsGroup"

const SalesManagerDashboardCards = () => {
  const { salesManagerDashboard } = useDashboardStore()
  const { actionLoader, loader } = salesManagerDashboard

  if (actionLoader.isLoading || loader.isLoading)
    return <Loading height="80vh" />

  const importantInfoCards = getImportantInfoCards(salesManagerDashboard)
  const increasedRentCards = getIncreasedRentCards(salesManagerDashboard)
  const newObjectsCards = getNewObjectsCards(salesManagerDashboard)

  return (
    <Grid container spacing={4}>
      <DashboardCardsGroup cards={importantInfoCards} />
      <DashboardCardsGroup cards={increasedRentCards} />
      <DashboardCardsGroup cards={newObjectsCards} />
    </Grid>
  )
}

export default observer(SalesManagerDashboardCards)
