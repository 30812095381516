import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import InspectionLinkButton from "../InspectionLinkButton"
import useInspectionStore from "../../store"
import RegularInspectionStore from "../../store/variations/RegularInspectionStore"
import TransferInspectionStore from "../../store/variations/TransferInspectionStore"
import InventoryInspectionStore from "../../store/variations/InventoryInspectionStore"
import { ApartmentLiteContext } from "../../../../../../store/shared/apartment"
import { apartmentStore } from "../../../../store"

const InspectionLinks = () => {
  const { inspectionInfo, type } = useInspectionStore()
  const inspection = inspectionInfo as
    | RegularInspectionStore
    | TransferInspectionStore
    | InventoryInspectionStore

  return (
    <ApartmentLiteContext.Provider value={apartmentStore}>
      <StyledWrapper container spacing={2}>
        {type !== "initial" && (
          <InspectionLinkButton
            id={inspection.impairmentsInspectionId}
            type="impairments"
            viewPermission="objectImpairmentsTab"
            createPermission="objectImpairmentsCreate"
          />
        )}
        <InspectionLinkButton
          id={inspection.appraisalInspectionId}
          type="appraisal"
          viewPermission="objectAppraisalsTab"
          createPermission="objectAppraisalCreate"
        />
      </StyledWrapper>
    </ApartmentLiteContext.Provider>
  )
}

export default observer(InspectionLinks)

const StyledWrapper = styled(Grid)`
  margin-left: 24px;
  width: fit-content;
`
