import React, { useState } from "react"
import { observer } from "mobx-react"
import ExpensesTabsSettings from "../ExpensesTabsSettings"
import MenuPanel from "../../../../../../../components/ui/MenuPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"
import ExpensesSummaryTab from "../../tabs/ExpensesSummaryTab"
import ExpensesMetersTab from "../../tabs/ExpensesMetersTab"
import ExpensesServicesTab from "../../tabs/ExpensesServicesTab"
import ExpensesAttachmentsTab from "../../tabs/ExpensesAttachmentsTab"
import ProtectedTabs from "../../../../../../../components/common/ProtectedTabs"
import { ProtectedTabParams } from "../../../../../../../components/common/ProtectedTabs/types"

const tabs: ProtectedTabParams[] = [
  { label: "Summary", permission: "expensesSummaryView" },
  { label: "Metering devices", permission: "expensesMetersView" },
  { label: "Lines", permission: "expensesLinesView" },
  { label: "Attachments", permission: "expensesDocumentsView" },
]

const paths = [
  `?tab=expenses&subtab=summary`,
  `?tab=expenses&subtab=metering-devices`,
  `?tab=expenses&subtab=lines`,
  `?tab=expenses&subtab=attachments`,
]

const tabPanels = [
  <ExpensesSummaryTab />,
  <ExpensesMetersTab />,
  <ExpensesServicesTab />,
  <ExpensesAttachmentsTab />,
]

function ExpensesTabsPanel() {
  const activeTab = useTabIndex(paths, "subtab")
  const [currentTab, setCurrentTab] = useState(Number(activeTab))

  return (
    <ProtectedTabs tabs={tabs} tabPanels={tabPanels}>
      <MenuPanel
        activeTab={Number(activeTab)}
        paths={paths}
        label="Expenses"
        handleChange={setCurrentTab}
        endComponent={<ExpensesTabsSettings activeTab={Number(currentTab)} />}
      />
    </ProtectedTabs>
  )
}

export default observer(ExpensesTabsPanel)
