import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { InfoIcon } from "kui-icon"
import { Tooltip } from "kui-crm"
import { InfoTooltipProps } from "./types"

const InfoTooltip = ({ content, className, ...other }: InfoTooltipProps) => (
  <StyledTooltip
    content={
      <Caption size="xs" {...other}>
        {content}
      </Caption>
    }
    placement="bottom"
  >
    <StyledIconWrapper container alignItems="center">
      <InfoIcon className={className} />
    </StyledIconWrapper>
  </StyledTooltip>
)

export default InfoTooltip

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper {
    max-width: 224px !important;
  }
`
const StyledIconWrapper = styled(Grid)`
  width: auto;
  cursor: pointer;
`
