import _ from "lodash"
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import { ImageBodyRequest } from "kui-crm"
import ApartmentImagesAgent from "../../../../../../../agent/ApartmentImages"
import {
  ApartmentImagesRes,
  PatchImageParams,
  PostApartmentImagesRequest,
} from "../../../../../types/api/apartmentPhotoAPI"
import ApartmentImageStore from "./ApartmentImage"
import { UploadFileFields } from "../../../../../../../types/common"
import ApartmentPageStore from "../../../../../store/ApartmentPageStore"
import { uploadImages } from "../../../../../../../utils/agent/uploadFiles"
import longPolling from "../../../../../../../utils/agent/longPolling"

class ApartmentImagesStore {
  apartmentStore: ApartmentPageStore

  images: ApartmentImageStore[]

  loader: Loader

  actionLoader: Loader

  isUploaded: boolean

  readonly previewImagesLimit: number

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.images = []
    this.previewImagesLimit = 6
    this.isUploaded = false
    makeAutoObservable(this, { apartmentStore: false })
  }

  checkImagesIsUploaded = async (apartmentId: number) => {
    if (!this.isUploaded) {
      const res = await longPolling<ApartmentImagesRes>(
        () => ApartmentImagesAgent.getAll(apartmentId),
        (error) => this.loader.setError(error),
        (response) => response.uploaded,
        2000
      )

      if (res) this.updateImages(res)
    }
  }

  fetchImages = async (apartmentId: number) => {
    this.loader.startLoading()

    await this.checkImagesIsUploaded(apartmentId)

    this.loader.endLoading()
  }

  actualizePublicPriority = async () => {
    this.publicImages.forEach((image, index) => {
      if (image.priority !== index + 1) {
        image.changePublicPriority(index + 1)
      }
    })
  }

  actualizePreviewPriority = async () => {
    this.previewImages.forEach((image, index) => {
      if (image.previewPriority !== index + 1) {
        image.changePreviewPriority(index + 1)
      }
    })
  }

  updateImages = (res: ApartmentImagesRes) => {
    this.isUploaded = res.uploaded
    this.images = res.images.map(
      (image) => new ApartmentImageStore(image, this)
    )
  }

  setLastSelectedImageNumber = (num: number) => {
    this.images.forEach((image) => image.setLastSelectedNumber(num))
  }

  setDragImage = (id: number, index: number) => {
    this.images.forEach((image) => image.setDragImage(id, index))
  }

  getImageById = (id: number) => this.images.find((image) => image.id === id)

  clearActiveImages = () => {
    this.images.forEach((image) => {
      image.setActiveNumber(0)
      image.setLastSelectedNumber(0)
    })
  }

  deleteSelectedImagesFromPublic = async () => {
    this.actionLoader.startLoading("images changes")

    const promises = []
    for (let i = 0; i < this.selectedImages.length; i += 1) {
      promises.push(
        this.selectedImages[i].patchImage({
          public: false,
        })
      )
    }
    await Promise.allSettled(promises)
    this.actualizePublicPriority()
    this.actualizePreviewPriority()

    this.actionLoader.endLoading()
  }

  deleteSelectedImages = async () => {
    this.actionLoader.startLoading("images removal")

    const promises = []
    for (let i = 0; i < Number(this.selectedImages.length); i += 1) {
      promises.push(this.selectedImages[i].delete(true))
    }
    await Promise.allSettled(promises)
    await this.actualizePublicPriority()

    this.actionLoader.endLoading()
  }

  makeSelectedImagesPublic = async () => {
    this.actionLoader.startLoading("public images")

    await Promise.allSettled(
      this.selectedImages.map((image) => image.makePublic(false))
    )

    this.actionLoader.endLoading()
  }

  makeSelectedImagesPreview = async () => {
    this.actionLoader.startLoading("preview images")

    await Promise.allSettled(
      this.selectedImages.map((image) => image.makePreview(false))
    )

    this.actionLoader.endLoading()
  }

  makeAllImagesNotMain = () => {
    this.images.forEach((image) => image.setMain(false))
  }

  loadImages = async (data: UploadFileFields, callback: Function) => {
    const images = await uploadImages(this.actionLoader, data.files, callback)
    await this.addImagesToApartment(images)
  }

  addImagesToApartment = async (images: ImageBodyRequest[]) => {
    this.isUploaded = false
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.actionLoader.cleanError()

      const body = { images }

      const response = await to(
        ApartmentImagesAgent.addImagesToApartment(apartmentId, body)
      )

      this.checkImagesIsUploaded(apartmentId)

      runInAction(() => {
        resHandler(
          response,
          this.actionLoader,
          this.addImages,
          "add images to apartment",
          {
            withEndLoading: false,
          }
        )
      })
    }
  }

  addImages = (res: PostApartmentImagesRequest) => {
    const newImages = res.images.map(
      (image) =>
        new ApartmentImageStore(
          {
            ...image,
            image_medium_url: image.image_large_url,
            image_small_url: image.image_large_url,
          },
          this
        )
    )
    this.images.push(...newImages)
  }

  deleteImage = (id: number) => {
    this.images = this.images.filter((image) => image.id !== id)
  }

  patchImage = async (id: number, data: Partial<PatchImageParams>) => {
    await this.images.find((image) => image.id === id)?.patchImage(data)
  }

  updatePreviewImage = (previewImage: string | null) => {
    this.apartmentStore.overviewStore.updatePreviewImage(previewImage)
  }

  makeMainNextImage = () => {
    if (this.previewImages.length) {
      this.previewImages[0].setMain(true)
      this.updatePreviewImage(this.previewImages[0].smallImageUrl)
    } else this.updatePreviewImage(null)
  }

  changePreviewPriority = async (
    replacementImage: ApartmentImageStore,
    replaceableImage: ApartmentImageStore
  ) => {
    await ApartmentImagesStore.shiftImagesPriority(
      replacementImage,
      replaceableImage,
      this.previewImages,
      "changePreviewPriority"
    )
    await this.actualizePreviewPriority()
  }

  changePublicPriority = async (
    replacementImage: ApartmentImageStore,
    replaceableImage: ApartmentImageStore
  ) => {
    await ApartmentImagesStore.shiftImagesPriority(
      replacementImage,
      replaceableImage,
      this.publicImages,
      "changePublicPriority"
    )
    await this.actualizePublicPriority()
  }

  get nonPreviewImages() {
    return this.publicImages.filter((image) => !image.isPreview)
  }

  get nonPublicImages() {
    return this.images.filter((image) => !image.isPublic)
  }

  get publicImages() {
    return this.images.length > 0
      ? _.sortBy(
          this.images.filter((image) => image.isPublic),
          ["priority"]
        )
      : []
  }

  get previewImages() {
    const preview = _.sortBy(
      this.images.filter((image) => image.isPreview && !image.isMain),
      ["previewPriority"]
    )

    return [...(this.mainImage ? [this.mainImage] : []), ...preview]
  }

  get mainImage() {
    return this.images.find((image) => image.isMain)
  }

  get visibleImages() {
    return this.images.length > 0 ? this.images.slice(0, 7) : null
  }

  get unVisibleImage() {
    return this.images[7]
  }

  get unVisibleImagesCount() {
    return this.images.length > 0 ? this.images.slice(7).length : null
  }

  get selectedImages() {
    return this.images.filter((image) => image.activeNumber > 0)
  }

  get previewExceededLimit() {
    return this.previewImages.length >= this.previewImagesLimit
  }

  static shiftImagesPriority = async (
    replacementImage: ApartmentImageStore,
    replaceableImage: ApartmentImageStore,
    images: ApartmentImageStore[],
    methodName: "changePreviewPriority" | "changePublicPriority"
  ) => {
    const replaceableImageIndex = images.findIndex(
      (image) => image.id === replaceableImage.id
    )
    const replacementImageIndex = images.findIndex(
      (image) => image.id === replacementImage.id
    )
    const dragToStart = replaceableImageIndex < replacementImageIndex // проверяем в каком направлении перетащили фотку (с конца в начало или наоборот)

    const newPriority = replaceableImageIndex + 1
    const replaceableImages = images
      .slice(
        dragToStart ? replaceableImageIndex : replacementImageIndex,
        dragToStart ? replacementImageIndex + 1 : replaceableImageIndex + 1
      )
      .filter((image) => image.id !== replacementImage.id)

    await Promise.allSettled([
      replacementImage[methodName](newPriority),
      ...replaceableImages.map((image, index) =>
        image[methodName](
          dragToStart
            ? newPriority + index + 1
            : newPriority - replaceableImages.length + index
        )
      ),
    ])
  }
}

export default ApartmentImagesStore
