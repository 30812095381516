import React from "react"
import { AnimalIcon, ChildIcon, NoAnumalIcon, NoChildIcon } from "kui-icon"
import ApartmentDescriptionStore from "../../../../../../../store/description/ApartmentDescription"
import { PromotionFeatureParams } from "../PromotionFeature/types"

const getContractConditions = (
  descriptionStore: ApartmentDescriptionStore
): PromotionFeatureParams[] => [
  {
    label: "With children",
    icon: <ChildIcon />,
    oppositeIcon: <NoChildIcon />,
    oppositeLabel: "Without children",
    value: descriptionStore.withChildren,
  },
  {
    label: "With pets",
    icon: <AnimalIcon />,
    oppositeIcon: <NoAnumalIcon />,
    oppositeLabel: "Without pets",
    value: descriptionStore.withPets,
  },
]

export default getContractConditions
