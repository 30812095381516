import { makeAutoObservable } from "mobx"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import ApartmentDocumentsRegistryStore from "./variants/ApartmentDocumentsRegistryStore"
import ServicesRegistryStore from "./variants/ServicesRegistryStore"
import InspectionsRegistryStore from "./variants/InspectionsRegistryStore"
import MetersReadingRegistryStore from "./variants/MetersReadingRegistryStore"
import TenantAppraisalsRegistryStore from "./variants/TenantAppraisalsRegistryStore"
import LandlordAppraisalsRegistryStore from "./variants/LandlordAppraisalsRegistryStore"
import ImpairmentsRegistryStore from "./variants/ImpairmentsRegistryStore"

class RegistriesPageStore {
  registriesStores: any[]

  activeRegistryStore: MultipleEntitiesStoreInterface | null

  constructor() {
    this.registriesStores = this.getRegistriesList()
    this.activeRegistryStore = null
    makeAutoObservable(this)
  }

  setActiveRegistry = (index: number) => {
    this.activeRegistryStore = this.registriesStores[index]
  }

  getRegistriesList = () => [
    new ServicesRegistryStore(),
    new InspectionsRegistryStore(),
    new TenantAppraisalsRegistryStore(),
    new LandlordAppraisalsRegistryStore(),
    new ImpairmentsRegistryStore(),
    new ApartmentDocumentsRegistryStore("insurances"),
    new ApartmentDocumentsRegistryStore("proxies"),
    new MetersReadingRegistryStore(),
  ]

  clear = () => {
    this.registriesStores = this.getRegistriesList()
    this.activeRegistryStore = null
  }
}

export default RegistriesPageStore
