import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import {
  ApartmentChartLegendProps,
  ApartmentChartLegendStylesProps,
} from "./types"
import InfoTooltip from "../../../../../../../../../components/common/InfoTooltip"

const ApartmentChartLegend = (props: ApartmentChartLegendProps) => {
  const { label, color, icon, hint } = props

  return (
    <Grid item>
      <Grid container alignItems="center">
        {color ? <StyledLine color={color} /> : icon}
        <StyledLabel size="s" color="fiftyP">
          {label}
        </StyledLabel>
        {hint && <StyledInfoTooltip content={hint} color="fiftyP" />}
      </Grid>
    </Grid>
  )
}

export default ApartmentChartLegend

const StyledLine = styled.div<ApartmentChartLegendStylesProps>`
  width: 30px;
  height: 4px;
  background: ${({ color }) => color};
  border-radius: 2px;
`

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 8px;
`

const StyledLabel = styled(Caption)`
  margin-left: 16px;
`
