import styled from "@emotion/styled"
import TabsPanel from "../TabsPanel"

const ListTabsPanel = styled(TabsPanel)`
  .KUI-TabsContainer {
    top: 64px;
    background: white;
    padding-top: 16px;
  }
  .KUI-TabList {
    border-bottom: 1px solid ${({ theme }) => theme.palette.background.light2};
  }
  .KUI-TabsWrapper {
    padding: 0 24px;
  }
  .KUI-Tab {
    height: 55px;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
`

export default ListTabsPanel
