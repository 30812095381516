import React from "react"
import TableRow from "../../../../../../components/ui/TableRow"
import { ImpairmentsRegistryRowProps } from "./types"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import ImpairmentsStatus from "../ImpairmentsStatus"

const ImpairmentsRegistryRow = (props: ImpairmentsRegistryRowProps) => {
  const { inspection, isDark } = props

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {inspection.id}
      </TableCell>
      <TableCell>{inspection.apartment?.folderNumber}</TableCell>
      <TableCell>
        <ApartmentLinkCell apartment={inspection.apartment} />
      </TableCell>
      <TableCell>{inspection.date?.toFormat("dd.MM.yyyy")}</TableCell>
      <TableCell>{inspection.price?.toLocaleString()} ₽</TableCell>
      <TableCell isLast>
        <ImpairmentsStatus impairments={inspection} />
      </TableCell>
    </TableRow>
  )
}

export default ImpairmentsRegistryRow
