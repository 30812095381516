import { addToArrayByCondition } from "kui-utils"
import { InputParams, LinkField, InspectionTypes } from "kui-crm"
import {
  ApartmentWithRentalTransferValues,
  ApartmentWithServiceTransferValues,
  ClientRoleValues,
} from "../../../../../utils/content/listsOptions"
import InspectionTypeFiled from "../InspectionTypeFiled"

const getTransferTypesOptions = (fields?: any) => {
  if (fields?.hasServiceContract && !fields?.hasRentalContract)
    return ApartmentWithServiceTransferValues
  if (fields?.hasServiceContract && fields?.hasRentalContract)
    return ApartmentWithRentalTransferValues

  return []
}

const getInspectionCommonStepFields = (
  withType?: boolean,
  type?: InspectionTypes,
  fields?: any,
  shouldUploadContract?: boolean
): InputParams<any>[] => [
  { label: "Inspection date", name: "date", variant: "date", isRequired: true },
  ...addToArrayByCondition(withType, {
    name: "type",
    variant: "custom",
    CustomInput: InspectionTypeFiled,
  }),
  ...addToArrayByCondition(type === "transfer", {
    label: "Transfer type",
    name: "transferType",
    variant: "select",
    options: getTransferTypesOptions(fields),
    isRequired: true,
  }),
  ...addToArrayByCondition(type === "appraisal" && fields?.basedOn, {
    label: "Client",
    name: "clientRole",
    variant: "select",
    options: ClientRoleValues,
    disabled: !fields?.hasRentalContract,
    value: fields?.hasRentalContract ? undefined : "landlord",
    isRequired: true,
  }),
  ...addToArrayByCondition(shouldUploadContract, {
    label: "Signed contract file",
    name: "contractFile",
    variant: "file",
    isRequired: true,
  }),
  {
    label: "Dropbox link",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default getInspectionCommonStepFields
