import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const NewApartmentFormSchema = yup.object().shape(
  {
    apartment: yup.object({
      address: yup.string().required("This field is required"),
      location: yup.object({
        lat: yup.number(),
        lon: yup.number(),
      }),
    }),
    city: yup.object({
      name: yup.string().required(defaultRequiredMessage),
    }),
    owner: yup.object({
      name: yup.string().required("This field is required"),
    }),
    floor: yup
      .number()
      .integer()
      .positive("Must be a positive")
      .required(defaultRequiredMessage)
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
  },
  [["floor", "floor"]]
)

export default NewApartmentFormSchema
