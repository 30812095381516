import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { useEffect, useState } from "react"
import { LoaderState } from "kui-crm"
import styled from "@emotion/styled"
import ExpensesPayments from "./ExpensesPayments"
import ExpensesSummaryCalculations from "./ExpensesSummaryCalculations"
import ExpensesFooter from "./ExpensesFooter"
import Loading from "../../../../../../../components/common/Loading"
import useExpensesStore from "../../../store"
import SnowflakeExplosion from "../../../../../../../components/common/SnowflakeExplosion"

function ExpensesSummaryTab() {
  const [isPeriodClosed, setPeriodClosed] = useState(false)
  const { overviewStore, periodsStore, summaryStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  const handlePeriodClosed = () => setPeriodClosed(true)

  useEffect(() => {
    if (overviewStore.id && selectedPeriod?.id) {
      summaryStore.initSummaryTab(
        overviewStore.id,
        selectedPeriod?.rentalContract?.id,
        selectedPeriod.id
      )
    } else {
      summaryStore.cleanSummaryTab()
    }
    return () => {
      summaryStore.loader.startLoading()
    }
  }, [selectedPeriod?.id, overviewStore.id])

  if (summaryStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <StyledWrapper pb={8} pt={1}>
      {isPeriodClosed && (
        <SnowflakeExplosion
          duration={3000}
          count={150}
          width={700}
          height="700px"
        />
      )}

      <ExpensesPayments />
      <ExpensesSummaryCalculations />
      <ExpensesFooter onClose={handlePeriodClosed} />

      <LoaderState loader={summaryStore.loader} onlyError />
      <LoaderState loader={summaryStore.actionLoader} />
      <LoaderState loader={summaryStore.paymentSummaryStore.loader} />
    </StyledWrapper>
  )
}

export default observer(ExpensesSummaryTab)

const StyledWrapper = styled(Box)`
  position: relative;
`
