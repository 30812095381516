import { UseFormReturn } from "react-hook-form"

export const getFormValue = <T extends object>(
  name?: string,
  form?: UseFormReturn<T> | null
) => {
  const names = name?.split(".")
  let fieldValue = form?.control._formValues
  names?.forEach((field: string) => {
    if (fieldValue) {
      fieldValue = fieldValue[field]
    }
  })
  return fieldValue
}

export function getDirtyValues(
  dirtyFields: object | boolean,
  allValues: object
): object {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues(
        dirtyFields[key as keyof typeof dirtyFields],
        allValues[key as keyof typeof allValues]
      ),
    ])
  )
}
