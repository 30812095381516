import React from "react"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import useExpensesStore from "../../../../store"
import ApartmentSummaryStore from "../../../../store/summary/ApartmentSummary"
import useApartmentStore from "../../../../../../store"
import ProtectedNode from "../../../../../../../../components/common/ProtectedNode"

function DownloadReportButton() {
  const { isArchived } = useApartmentStore()
  const { overviewStore, date } = useExpensesStore()

  const handlePDFOpen = async () => {
    if (overviewStore.id && date) {
      const agentReport = ApartmentSummaryStore.getPreviewAgentReportLink(
        overviewStore.id,
        date
      )
      if (agentReport) {
        window.open(agentReport, "_blank")
      }
    }
  }

  return (
    <Grid item>
      <ProtectedNode withTooltip permission="expensesSummaryEdit">
        <Button
          variant="white"
          size="s"
          onClick={handlePDFOpen}
          disabled={isArchived}
        >
          Agent report
        </Button>
      </ProtectedNode>
    </Grid>
  )
}

export default observer(DownloadReportButton)
