import React, { useState } from "react"
import { observer } from "mobx-react"
import _ from "lodash"
import { MultistepForm } from "kui-crm"
import ComparableObjectStep from "./ComparableObjectStep"
import StatisticalValuationStep from "./StatisticalValuationStep"
import ManagerValuationStep from "./ManagerValuationStep"
import { AppraisalInspectionFormFields } from "./types"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import TenantOfferStep from "./TenantOfferStep"
import useResetMultistepForm from "../../../../../../hooks/useResetMultistepForm"
import WarningStep from "./WarningStep"

const getInitialState = (length?: number) => {
  if (!length) return [0]
  return _.range(length)
}

const AppraisalInspectionForm = (props: InspectionFormProps) => {
  const { handleCreate, shift, handleClose } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const [comparableObjects, setComparableObjects] = useState(
    getInitialState(formStore.fields?.comparableObjects?.length)
  )
  const [statisticalValuations, setStatisticalValuations] = useState(
    getInitialState(formStore.fields?.statisticalValuations?.length)
  )
  const clientRole = formStore.fields?.clientRole

  const addComparableObject = () => {
    setComparableObjects((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const addStatisticalValuation = () => {
    setStatisticalValuations((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const handleSubmit = async () => {
    handleClose()
    const inspectionId = await inspectionsStore.createAppraisalInspection(
      formStore.fields as AppraisalInspectionFormFields
    )

    if (inspectionId) handleCreate(inspectionId)
  }

  useResetMultistepForm(formStore)

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      {comparableObjects.map((object) => (
        <ComparableObjectStep
          key={object}
          index={object}
          addStep={addComparableObject}
        />
      ))}
      {statisticalValuations.map((valuation) => (
        <StatisticalValuationStep
          key={valuation}
          index={valuation}
          addStep={addStatisticalValuation}
        />
      ))}
      {clientRole === "tenant" ? (
        <TenantOfferStep handleSubmit={handleSubmit} />
      ) : (
        <ManagerValuationStep handleSubmit={handleSubmit} />
      )}
      {formStore.fields?.basedOnInitial && (
        <WarningStep handleSubmit={handleSubmit} />
      )}
    </MultistepForm>
  )
}

export default observer(AppraisalInspectionForm)
