import React from "react"
import styled from "@emotion/styled"
import { Grid, Heading, NewYearGarland, theme } from "kui-basic"
import { keyframes } from "@emotion/react"
import SnowflakeExplosion from "../../../../../components/common/SnowflakeExplosion"

const { brand, blue, red, purple } = theme.palette

const NewYearCard = () => {
  const explosionParams = {
    width: 400,
    height: "250px",
    duration: 3000,
    bezierMedian: 0.5,
  }

  return (
    <StyledWrapper container alignItems="center" justify="center">
      <SnowflakeExplosion {...explosionParams} />
      <SnowflakeExplosion
        {...explosionParams}
        colors={[
          brand.main,
          blue.fiftyP,
          brand.main,
          "#F9DA3A",
          red.fiftyP,
          brand.main,
          purple.fiftyP,
        ]}
        particleElement={<StyledParticle />}
        htmlTag="div"
        size={5}
      />

      <StyledTitle>
        <Heading size="h3" colorGroup="brand" color="main">
          С НАСТУПАЮЩИМ
        </Heading>
        <Heading size="h2" colorGroup="red">
          НОВЫМ ГОДОМ 😊🥂
        </Heading>
      </StyledTitle>

      <StyledGarland width={400} />
    </StyledWrapper>
  )
}

export default NewYearCard

const fadeAnimation = keyframes`
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
`

const StyledWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 12px;
  animation: ${fadeAnimation} 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 4s both;
  will-change: transform;
}
`

const showAnimation = keyframes`
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
`

const StyledTitle = styled.div`
  text-align: center;
  animation: ${showAnimation} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  will-change: transform;
`

const StyledParticle = styled.div`
  border-radius: 50%;
`

const blinkAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

const StyledGarland = styled(NewYearGarland)`
  position: absolute;
  top: 0;
  animation: ${blinkAnimation} 2s linear 1s both;
`
