import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { ContractIncreasedRentRowProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"

const getColorGroup = (value: number, isNew: boolean) => {
  if (value < 0) return "red"
  if (isNew) return "green"
  return "grey"
}

const ContractIncreasedRentRow = (props: ContractIncreasedRentRowProps) => {
  const { contract } = props
  const { apartment, increasedRentValue } = contract
  const link = contract.isNew
    ? DynamicRoutes.apartment(apartment.id)
    : DynamicRoutes.contract(contract.id, "rental")
  const formattedValue = `${Math.abs(increasedRentValue).toLocaleString()} ₽`
  const valueColorGroup = getColorGroup(increasedRentValue, contract.isNew)

  return (
    <StyledLink href={link}>
      <StyledRow container alignItems="center" justify="space-between">
        <StyledPreviewContent
          imageSrc={apartment.previewImage || defaultApartmentImageURL}
          description={apartment.address}
          label={
            <Grid container alignItems="center">
              <Caption size="xs" color="fiftyP">
                {contract.number}
              </Caption>
              {contract.isNew && (
                <StyledStatus>
                  <Caption size="xs" weight={500}>
                    new
                  </Caption>
                </StyledStatus>
              )}
            </Grid>
          }
        />
        <Caption
          size="s"
          weight={600}
          colorGroup={valueColorGroup}
          color="fiftyP"
        >
          {Number(increasedRentValue) < 0 ? "-" : "+"} {formattedValue}
        </Caption>
      </StyledRow>
    </StyledLink>
  )
}

export default ContractIncreasedRentRow

const StyledLink = styled(Link)`
  &:not(&:last-of-type) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
`

const StyledPreviewContent = styled(PreviewContent)`
  max-width: calc(100% - 100px);
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 6px;
  margin-left: 4px;
  background: ${({ theme }) => theme.palette.brand.light};
  p {
    color: ${({ theme }) => theme.palette.brand.main};
  }
`
