import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { DateTime } from "luxon"
import { ApartmentChartTooltipProps } from "./types"
import { getApartmentTooltipPrices } from "./content"

const ApartmentChartTooltip = (props: ApartmentChartTooltipProps) => {
  const { active, payload, label } = props
  if (!active || !payload || !payload.length) return null
  const data = payload[0].payload
  const prices = getApartmentTooltipPrices(data)

  const formattedDate =
    label &&
    DateTime.fromFormat(label, "dd.MM.yy").toFormat("dd MMMM").toLowerCase()

  return (
    <StyledTooltip>
      <StyledDate size="xs">{formattedDate}</StyledDate>
      {prices.map((price) => (
        <StyledLabel size="xs">
          <StyledAccent>{price.label}</StyledAccent>
          {price.value ? `${Number(price.value).toLocaleString()} ₽` : "-"}
        </StyledLabel>
      ))}
    </StyledTooltip>
  )
}

export default ApartmentChartTooltip

const StyledTooltip = styled.div`
  background: white;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
  padding: 18px 19px;
`

const StyledDate = styled(Caption)`
  margin-bottom: 8px;
`

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.sixty};
  font-weight: 600;
`

const StyledLabel = styled(Caption)`
  margin-bottom: 2px;
`
