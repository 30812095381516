import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { DateTime } from "luxon"
import { ApartmentVolumeTooltipProps } from "./types"

const ApartmentVolumeTooltip = (props: ApartmentVolumeTooltipProps) => {
  const { active, payload, label } = props
  if (!active || !payload || !payload.length) return null
  const data = payload[0].payload

  const formattedDate =
    label &&
    DateTime.fromFormat(label, "dd.MM.yy").toFormat("dd MMMM").toLowerCase()

  return (
    <StyledTooltip>
      <Caption size="xs">{formattedDate}</Caption>
      <Caption size="xs">{Number(data.price).toLocaleString()} ₽</Caption>
    </StyledTooltip>
  )
}

export default ApartmentVolumeTooltip

const StyledTooltip = styled.div`
  background: white;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
  padding: 18px 19px;
`
