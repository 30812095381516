import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import Loading from "../../../../../../../../components/common/Loading"
import ApartmentAllPhoto from "./ApartmentAllPhoto"
import ApartmentPublicPhoto from "./ApartmentPublicPhoto"
import ApartmentPreviewPhoto from "./ApartmentPreviewPhoto"
import ApartmentPhotoLoaders from "./ApartmentPhotoLoaders"
import SectionTitle from "../../../../../../../../components/common/SectionTitle"
import Container from "../../../../../../../../components/ui/Container"
import usePromotionStore from "../../../../store"

function PromotionPhoto() {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  if (imagesStore.loader.isLoading || !imagesStore.isUploaded) {
    return <Loading height="50vh" />
  }

  return (
    <>
      <Container>
        <SectionTitle title="Photos" />
      </Container>

      <ApartmentPreviewPhoto />
      <ApartmentPublicPhoto />
      <ApartmentAllPhoto />

      <ApartmentPhotoLoaders />

      <LoaderState loader={imagesStore.actionLoader} />
    </>
  )
}

export default observer(PromotionPhoto)
