import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import { ServiceContractListModel } from "kui-crm"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import ServiceContractStore from "./ServiceContractStore"
import { FetchServiceContracts } from "../../../types/api/service_contracts"
import ServiceContractsAgent from "../../../agent/ServiceContracts"

class ServiceContractsStore implements MultipleEntitiesStoreInterface {
  contracts: ServiceContractStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  constructor() {
    this.contracts = []
    this.loader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<FetchServiceContracts>(
      ServiceContractsAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (contract: ServiceContractListModel) =>
          new ServiceContractStore(contract)

        this.contracts = this.paginator.getPageResponse<
          ServiceContractListModel,
          ServiceContractStore
        >(res, this.contracts, mapper)
      } else {
        this.loader.setError("fetch service contracts", err)
      }
      this.loader.endLoading()
    })
  }
}

export default ServiceContractsStore
