import React from "react"
import { ParticlesExplosion, theme } from "kui-basic"
import styled from "@emotion/styled"
import SnowflakeIcon from "../../icons/SnowflakeIcon"
import { SnowflakeExplosionProps } from "./types"

const { grey, brand, blue } = theme.palette
const colors = [
  grey.fifteenB,
  "#B2E2E2",
  "#EAFFFF",
  "#D6EFEF",
  brand.light,
  brand.main,
  blue.fiftyP,
]

const SnowflakeExplosion = (props: SnowflakeExplosionProps) => (
  <StyledParticlesExplosion
    width={120}
    height="40px"
    duration={1500}
    count={50}
    size={10}
    bezierMedian={0.5}
    colors={colors}
    particleElement={<SnowflakeIcon />}
    htmlTag="svg"
    {...props}
  />
)

export default SnowflakeExplosion

const StyledParticlesExplosion = styled(ParticlesExplosion)`
  z-index: 10;
  .particle {
    will-change: transform;
    svg {
      will-change: transform;
    }
  }
`
