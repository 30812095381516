import requests from "./index"
import { PatchServiceContractParams } from "../types/api/service_contracts"

const ServiceContractsAgent = {
  all: (offset: number, limit: number, params: string) =>
    requests.get(`/service-contracts?offset=${offset}&limit=${limit}${params}`),
  getById: (id: number) => requests.get(`/service-contracts/${id}`),
  patch: (id: number, data: PatchServiceContractParams) =>
    requests.patch(`/service-contracts/${id}`, data),
  delete: (id: number) => requests.delete(`/service-contracts/${id}`),
}

export default ServiceContractsAgent
