import React from "react"
import { RubleIcon } from "kui-icon"
import { observer } from "mobx-react"
import { ObjectsWithoutAppraisalCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import ObjectWithoutAppraisalRow from "./ObjectWithoutAppraisalRow"
import useDashboardStore from "../../../store"

const ObjectsWithoutAppraisalCard = (
  props: ObjectsWithoutAppraisalCardProps
) => {
  const { objectsStore } = props
  const { salesManagerDashboard } = useDashboardStore()
  const { objects, value, paginator, loader, fetchObjects } = objectsStore

  return (
    <DashboardListCard
      withCongrats={salesManagerDashboard.congratsCard === 0}
      value={value}
      label="Objects need appraisal"
      icon={<RubleIcon />}
      paginator={paginator}
      loader={loader}
      fetchAll={fetchObjects}
    >
      {objects.map((object) => (
        <ObjectWithoutAppraisalRow key={object.id} object={object} />
      ))}
    </DashboardListCard>
  )
}

export default observer(ObjectsWithoutAppraisalCard)
