import React from "react"
import { AppraisalStatusProps } from "./types"
import { appraisalStatusColors, appraisalStatusLabels } from "./content"
import StatusButton from "../StatusButton"
import { StatusButtonColors } from "../StatusButton/types"

const AppraisalStatus = (props: AppraisalStatusProps) => {
  const { appraisal } = props

  return (
    <StatusButton
      inspection={appraisal}
      label={
        appraisal.status
          ? appraisalStatusLabels[appraisal.status]
          : "Without offer"
      }
      variant={
        appraisalStatusColors[
          appraisal.status || "notSigned"
        ] as StatusButtonColors
      }
    />
  )
}

export default AppraisalStatus
