const inspectionHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "apartment", label: "Object", width: "52%" },
  { id: "date", label: "Creation date", width: "12%" },
  { id: "price", label: "Price", width: "11%" },
  { id: "status", label: "Status", width: "15%" },
]

export default inspectionHeadCells
