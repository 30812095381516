import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { CreateModalProps } from "../../../../types/ui/modal"
import CreateServiceContractsForm from "../../forms/CreateServiceContractForm"

function ServiceContractCreationModal({
  isOpen,
  handleClose,
}: CreateModalProps) {
  return (
    <Modal open={isOpen} handleClose={handleClose} title="New service contract">
      <CreateServiceContractsForm />
    </Modal>
  )
}

export default observer(ServiceContractCreationModal)
