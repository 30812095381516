import React, { SVGProps } from "react"

const SnowflakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52814 14.1497C9.15399 14.1497 8.85068 13.8464 8.85068 13.4722V-3.36666C8.85068 -3.74082 9.15399 -4.04413 9.52814 -4.04413C9.9023 -4.04413 10.2056 -3.74082 10.2056 -3.36666V13.4722C10.2056 13.8464 9.9023 14.1497 9.52814 14.1497Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04938 -0.088058C8.78481 -0.352626 8.78481 -0.781575 9.04938 -1.04614L11.8489 -3.84564C12.1134 -4.11021 12.5424 -4.11021 12.807 -3.84564C13.0715 -3.58107 13.0715 -3.15212 12.807 -2.88756L10.0075 -0.088058C9.74289 0.17651 9.31395 0.17651 9.04938 -0.088058Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24958 -3.84569C6.51415 -4.11026 6.9431 -4.11026 7.20767 -3.84569L10.0072 -1.04619C10.2717 -0.781621 10.272 -0.352626 10.0075 -0.088058C9.74289 0.17651 9.31395 0.17651 9.04938 -0.088058L6.24958 -2.8876C5.98502 -3.15217 5.98502 -3.58112 6.24958 -3.84569Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04938 10.1937C8.78481 10.4583 8.78481 10.8872 9.04938 11.1518L11.8489 13.9513C12.1134 14.2159 12.5424 14.2159 12.807 13.9513C13.0715 13.6867 13.0715 13.2578 12.807 12.9932L10.0075 10.1937C9.74289 9.92916 9.31395 9.92916 9.04938 10.1937Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24958 13.9513C6.51415 14.2158 6.9431 14.2158 7.20767 13.9513L10.0072 11.1518C10.2717 10.8872 10.272 10.4583 10.0075 10.1937C9.74289 9.92916 9.31395 9.92916 9.04938 10.1937L6.24958 12.9932C5.98502 13.2577 5.98502 13.6867 6.24958 13.9513Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4079 9.60122C17.2208 9.92524 16.8065 10.0363 16.4825 9.84919L1.89959 1.42975C1.57556 1.24267 1.46454 0.82834 1.65162 0.504312C1.83869 0.180283 2.25303 0.069263 2.57705 0.256341L17.1599 8.67578C17.484 8.86286 17.595 9.27719 17.4079 9.60122Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83886 2.89718C4.47745 2.99402 4.10597 2.77954 4.00913 2.41813L2.98445 -1.40605C2.88761 -1.76746 3.10208 -2.13894 3.46349 -2.23578C3.8249 -2.33262 4.19638 -2.11814 4.29322 -1.75673L5.3179 2.06745C5.41474 2.42886 5.20027 2.80034 4.83886 2.89718Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.185013 3.44285C0.0881747 3.08144 0.302649 2.70996 0.664056 2.61312L4.48824 1.58843C4.84965 1.49159 5.22106 1.70604 5.3179 2.06745C5.41474 2.42886 5.20027 2.80034 4.83886 2.89718L1.01474 3.92189C0.653333 4.01873 0.281852 3.80425 0.185013 3.44285Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7425 8.03804C13.8393 8.39945 14.2108 8.61392 14.5722 8.51708L18.3964 7.4924C18.7578 7.39556 18.9723 7.02408 18.8754 6.66267C18.7786 6.30126 18.4071 6.08679 18.0457 6.18363L14.2215 7.20832C13.8601 7.30515 13.6456 7.67663 13.7425 8.03804Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5965 12.3413C15.9579 12.2445 16.1724 11.873 16.0755 11.5116L15.0509 7.68739C14.954 7.32599 14.5829 7.11148 14.2215 7.20832C13.8601 7.30515 13.6456 7.67663 13.7425 8.03804L14.7668 11.8623C14.8636 12.2237 15.2351 12.4381 15.5965 12.3413Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.65028 9.60121C1.4632 9.27718 1.57422 8.86285 1.89825 8.67577L16.4811 0.256333C16.8052 0.0692554 17.2195 0.180276 17.4066 0.504304C17.5937 0.828332 17.4826 1.24266 17.1586 1.42974L2.57572 9.84918C2.25169 10.0363 1.83736 9.92524 1.65028 9.60121Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7407 2.06746C13.8375 1.70605 14.209 1.49158 14.5704 1.58842L18.3946 2.61311C18.756 2.70994 18.9705 3.08142 18.8736 3.44283C18.7768 3.80424 18.4053 4.01871 18.0439 3.92187L14.2197 2.89719C13.8583 2.80035 13.6439 2.42887 13.7407 2.06746Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5947 -2.23573C15.9561 -2.13889 16.1705 -1.76741 16.0737 -1.406L15.049 2.41818C14.9522 2.77959 14.5811 2.99402 14.2197 2.89719C13.8583 2.80035 13.6439 2.42887 13.7407 2.06746L14.7649 -1.75669C14.8618 -2.11809 15.2333 -2.33257 15.5947 -2.23573Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83605 7.2084C4.47464 7.11156 4.10316 7.32604 4.00632 7.68744L2.98163 11.5116C2.8848 11.873 3.09927 12.2445 3.46068 12.3414C3.82208 12.4382 4.19356 12.2237 4.2904 11.8623L5.31509 8.03813C5.41193 7.67672 5.19745 7.30524 4.83605 7.2084Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.182432 6.66272C0.0855935 7.02413 0.300068 7.39561 0.661474 7.49245L4.48566 8.51714C4.84707 8.61397 5.21825 8.39953 5.31509 8.03813C5.41193 7.67672 5.19745 7.30524 4.83605 7.2084L1.01216 6.18368C0.650751 6.08684 0.279271 6.30132 0.182432 6.66272Z"
      fill="white"
    />
  </svg>
)

export default SnowflakeIcon
