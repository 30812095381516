import { ContractStatuses } from "kui-crm"

const isContractActive = (status?: ContractStatuses) => {
  if (
    !status ||
    status === "Closed" ||
    status === "Archived" ||
    status === "Pending"
  )
    return false
  return true
}

export default isContractActive
