import { theme } from "kui-basic"

export const statusColors = {
  red: {
    color: theme.palette.red.fiftyP,
    background: theme.palette.red.five,
  },
  green: {
    color: theme.palette.green.fiftyP,
    background: theme.palette.green.five,
  },
  grey: {
    color: theme.palette.grey.fiftyP,
    background: theme.palette.background.light1,
  },
}
