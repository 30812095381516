import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { useEffect } from "react"
import usePromotionStore from "./store"
import PromotionTabsPanel from "./components/common/PromotionTabsPanel"
import useApartmentStore from "../../store"

function ApartmentPromotion() {
  const { setEditPermission } = useApartmentStore()
  const { updatePromotionPage } = usePromotionStore()

  useEffect(() => {
    setEditPermission("objectPromotionView")
    return () => updatePromotionPage()
  }, [])

  return (
    <Box pt={3} pb={3}>
      <PromotionTabsPanel />
    </Box>
  )
}

export default observer(ApartmentPromotion)
