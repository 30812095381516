import React from "react"
import { observer } from "mobx-react"
import { ProtectedRouteProps } from "./types"
import useStore from "../../../hooks/useStore"
import NoAccessPage from "../NoAccessPage"

const ProtectedRouteContent = (props: ProtectedRouteProps) => {
  const { route } = props
  const { content, permission } = route
  const { cabinetStore } = useStore()
  const haveAccess = permission ? cabinetStore.isThereAccess(permission) : true

  return haveAccess ? <>{content}</> : <NoAccessPage />
}

export default observer(ProtectedRouteContent)
