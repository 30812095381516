import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button } from "kui-basic"
import { FormBottom, InputFileWithVisibility } from "kui-crm"
import { TrashIcon } from "kui-icon"
import InputWithDatePicker from "../../../ui/InputWithDatepicker"
import { CheckoutDateFormFields, ContractCheckoutDateFormProps } from "./types"
import ContractCheckoutDateFormSchema from "./schema"
import useResetForm from "../../../../hooks/useResetForm"

const ContractCheckoutDateForm = (props: ContractCheckoutDateFormProps) => {
  const { handleSubmit, defaultDate, label, resetDate, handleClose } = props

  const form = useForm<CheckoutDateFormFields>({
    defaultValues: {
      checkoutDate: defaultDate,
    },
    resolver: yupResolver(ContractCheckoutDateFormSchema),
  })

  useResetForm(form)

  const resetContractDate = () => {
    resetDate()
    handleClose()
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pr={5} pl={5} mb={3}>
        <Box mb={2}>
          <InputWithDatePicker
            label="Date"
            name="checkoutDate"
            description={`Select the expected ${label} date`}
            form={form}
          />
        </Box>
        <InputFileWithVisibility label="Document" name="reason" form={form} />
      </Box>

      <FormBottom
        startButton={
          defaultDate && (
            <Button
              data-testid="resetButton"
              variant="orange"
              size="s"
              onClick={resetContractDate}
              endIcon={<TrashIcon />}
            >
              Reset date
            </Button>
          )
        }
        label="Save"
      />
    </form>
  )
}

export default ContractCheckoutDateForm
