import React from "react"
import { PersonIcon } from "kui-icon"
import { observer } from "mobx-react"
import { NewContractsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import NewContractRow from "./NewContractRow"
import useDashboardStore from "../../../store"

const NewContractsCard = (props: NewContractsCardProps) => {
  const { contractsStore } = props
  const { consultantDashboard } = useDashboardStore()
  const { value, contracts, loader, fetchContracts, paginator } = contractsStore

  return (
    <DashboardListCard
      withCongrats={consultantDashboard.congratsCard === 0}
      value={value}
      label="New rental contracts"
      icon={<PersonIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchContracts}
      compareVariant="less"
    >
      {contracts.map((contract) => (
        <NewContractRow key={contract.id} contract={contract} />
      ))}
    </DashboardListCard>
  )
}

export default observer(NewContractsCard)
