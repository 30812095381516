import React from "react"
import { observer } from "mobx-react"
import { DateTime } from "luxon"
import _ from "lodash"
import { updateQueryParams } from "kui-utils"
import CalendarWithMonthButton from "../../../../../../../components/common/CalendarWithMonthButton"
import useExpensesStore from "../../../store"
import CheckoutDateWarning from "../CheckoutDateWarning"
import CustomMonthContent from "./CustomMonthContent"

function ExpensesCalendar() {
  const { setDate, date, overviewStore, lastOpenPeriod } = useExpensesStore()
  const diffFromNow = lastOpenPeriod?.diffNow(["month"]).months || 0
  const maxDate = diffFromNow > 0 ? lastOpenPeriod : DateTime.now()

  const handleChangeDate = (newDate: Date) => {
    const formattedDate = DateTime.fromJSDate(newDate)
    setDate(formattedDate)

    updateQueryParams(`&date=${formattedDate.toFormat("MM.yy")}`)
  }

  return (
    <CalendarWithMonthButton
      initialDate={date?.toJSDate() || new Date()}
      minDate={overviewStore.startDate}
      maxDate={maxDate}
      handleChange={handleChangeDate}
      title={_.capitalize(date?.toFormat("LLL yyyy"))}
      endIcon={<CheckoutDateWarning />}
      renderMonthContent={(monthIndex, shortName) => (
        <CustomMonthContent monthIndex={monthIndex} monthName={shortName} />
      )}
    />
  )
}

export default observer(ExpensesCalendar)
