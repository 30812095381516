import * as yup from "yup"
import {
  defaultRequiredMessage,
  InspectionBasedOnParams,
  InspectionTypes,
  TransferInspectionTypes,
} from "kui-crm"

const getInspectionCommonStepSchema = (withRC: boolean, withSC: boolean) =>
  yup.object().shape({
    type: yup.string().required("This field is required"),
    date: yup.mixed().required("This field is required"),
    clientRole: yup.string().when(["type", "basedOn"], {
      is: (type: InspectionTypes, basedOn: InspectionBasedOnParams) =>
        type === "appraisal" && basedOn,
      then: (rule) => rule.required(defaultRequiredMessage),
    }),
    transferType: yup.string().when("type", {
      is: (value: InspectionTypes) => value === "transfer",
      then: (rule) => rule.required(defaultRequiredMessage),
    }),
    contractFile: yup
      .mixed()
      .when("type", {
        is: (value: InspectionTypes) => value === "inventory" && !withSC,
        then: (rule) => rule.required(defaultRequiredMessage),
      })
      .when("transferType", {
        is: (value: TransferInspectionTypes) =>
          value === "maroom_tenant" && !withRC,
        then: (rule) => rule.required(defaultRequiredMessage),
      }),
  })

export default getInspectionCommonStepSchema
